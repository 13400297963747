import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DialogModule} from "primeng/dialog"

@Component({
  selector: 'lib-help',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
  ],
  templateUrl: './help.component.html',
  styleUrl: './help.component.sass',
})
export class HelpComponent {}
