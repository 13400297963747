import {Component} from '@angular/core'
import {CommonModule} from '@angular/common'
import {DialogModule} from "primeng/dialog"
import {environment} from "../../../../../apps/frontend/src/environments/environment"

@Component({
  selector: 'lib-support',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
  ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.sass',
})
export class SupportComponent {
  supportEmail = environment.supportEmail
  supportPhone = environment.supportPhone
}
