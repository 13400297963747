import { Route } from '@angular/router'
import { LoginComponent } from '@ui/authentication'
import { MapComponent } from '@ui/map'
import { HomeComponent } from '@ui/home'
import { AdminHomeComponent } from '@ui/admin-home'
import { PageNotFoundComponent } from '@ui/common'
import { JournalComponent } from '@ui/accounting'
import { AdminCalendarComponent } from '@ui/admin-calendar'
import { RoleType } from '@domain/authorization'
import { authGuard } from '@service/auth-guard'
import { DriversComponent } from '../../../../libs/ui/driver/src/lib/drivers.component'
import { EventReportComponent } from '@ui/reports'

export const appRoutes: Route[] = [
  {
    path: 'login',
    title: 'TixiPlan - Login',
    component: LoginComponent
  },
  {
    path: 'journal',
    title: 'TixiPlan - Rechnung',
    canActivate: [authGuard], data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.INVOICE
      ]
    },
    component: JournalComponent
  },
  {
    path: 'home',
    title: 'TixiPlan - Home',
    canActivate: [authGuard], data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.DRIVER
      ]
    },
    component: HomeComponent
  },
  {
    path: 'calendar',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    component: AdminCalendarComponent
  },
  {
    path: 'drivers-all',
    title: 'TixiPlan - Alle Fahrer',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.DRIVER,
        RoleType.RESPONSIBLE
      ]
    },
    component: DriversComponent
    // loadComponent: () => import('@ui/driver').then((c) => c.DriversComponent)
  },
  {
    path: 'driver-rides-all',
    title: 'TixiPlan - Alle Fahrten',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.DRIVER,
        RoleType.RESPONSIBLE
      ]
    },
    // component: DriverRideComponent
    loadComponent: () => import('@ui/driver').then((c) => c.DriverRideAllComponent)
  },
  {
    path: 'driver-rides',
    title: 'TixiPlan - Meine Fahrten',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.DRIVER
      ]
    },
    // component: DriverRideComponent
    loadComponent: () => import('@ui/driver').then((c) => c.DriverRideComponent)
  },
  {
    path: 'event-report',
    title: 'TixiPlan - Fahrtenplan',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN,
        RoleType.REPORTS
      ]
    },
    // component: EventReportComponent
    loadComponent: () => import('@ui/reports').then((c) => c.EventReportComponent)
  },
  {
    path: 'map',  // testing (works)
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.DRIVER,
        RoleType.PLAN
      ]
    },
    component: MapComponent
  },
  /*
   {
   path: 'admin',
   title: 'TixiPlan - Admin',
   canActivate: [authGuard],
   data: {
   roles: [
   RoleType.ADMINISTRATOR,
   RoleType.PLAN
   ]
   },
   // component: AdminWelcomeComponent,
   children: [
   // {path: 'users', component: UserComponent},
   // {path: 'home', component: AdminDashboardComponent},
   { path: 'home', component: AdminHomeComponent },
   { path: 'users', component: AdminUsersComponent }
   // {path: 'responsibilities', component: ResponsibilityComponent},
   ]
   },
   */
  {
    path: 'admin-home',
    title: 'TixiPlan - Admin Home',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    component: AdminHomeComponent
  },
  {
    path: 'admin-addresses',
    title: 'TixiPlan - Admin Adressen',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    loadComponent: () => import('@ui/admin-addresses').then((c) => c.AdminAddressesComponent)
    // component: AdminAddressesComponent
  },
  {
    path: 'admin-contacts',
    title: 'TixiPlan - Admin Kontakte',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    loadComponent: () => import('@ui/admin-contacts').then((c) => c.AdminContactsComponent)
    // component: AdminContactsComponent
  },
  {
    path: 'admin-users',
    title: 'TixiPlan - Admin Benutzer',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR
      ]
    },
    loadComponent: () => import('@ui/admin-users').then((c) => c.AdminUsersComponent)
    // component: AdminUsersComponent
  },
  {
    path: 'admin-calendar',
    title: 'TixiPlan - Admin Kalender',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    component: AdminCalendarComponent
  },
  {
    path: 'admin-vehicles',
    title: 'TixiPlan - Admin Fahrzeuge',
    canActivate: [authGuard],
    data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    loadComponent: () => import('@ui/admin-vehicles').then((c) => c.AdminVehiclesComponent)
    // component: AdminVehiclesComponent
  },
  {
    path: 'admin-responsibilities',
    title: 'TixiPlan - Admin Verantwortlichkeiten',
    canActivate: [authGuard], data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN,
        RoleType.RESPONSIBLE
      ]
    },
    loadComponent: () => import('@ui/admin-responsibilities').then((c) => c.AdminResponsibilitiesComponent)
    // component: AdminResponsibilitiesComponent
  },
  {
    path: 'admin-drivers',
    title: 'TixiPlan - Admin Fahrerzuteilung',
    canActivate: [authGuard], data: {
      roles: [
        RoleType.ADMINISTRATOR,
        RoleType.PLAN
      ]
    },
    loadComponent: () => import('@ui/admin-drivers').then((c) => c.AdminDriversComponent)
    // component: AdminDriversComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  // Wildcard route for a 404 page
  {
    path: '**',
    title: 'TixiPlan - Seite nicht gefunden',
    component: PageNotFoundComponent
  }
]
