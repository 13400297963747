import {APP_INITIALIZER, Component, ErrorHandler, LOCALE_ID, OnInit} from '@angular/core'
import {RouterModule} from '@angular/router'
import {MenubarModule} from "primeng/menubar"
import {InputTextModule} from "primeng/inputtext"
import {ButtonModule} from "primeng/button"
import {ToolbarModule} from "primeng/toolbar"
import {AvatarModule} from "primeng/avatar"
import {PanelModule} from "primeng/panel"
import {MenuModule} from "primeng/menu"
import {ImageModule} from "primeng/image"
import {TabViewModule} from "primeng/tabview"
import {CardModule} from "primeng/card"
import {MessagesModule} from "primeng/messages"
import {DialogService} from "primeng/dynamicdialog"
import {NavTopComponent} from '@ui/navigation'
import {NavBottomComponent} from '@ui/navigation'
// import {environment} from "../environments/environment"
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common"
import {registerLocaleData} from '@angular/common'
import localeDe from '@angular/common/locales/de-CH'

@Component({
  standalone: true,
  imports: [
    RouterModule,
    MessagesModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    ToolbarModule,
    AvatarModule,
    PanelModule,
    MenuModule,
    ImageModule,
    TabViewModule,
    CardModule,
    NavTopComponent,
    NavBottomComponent,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-CH'},
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {dateFormat: 'dd.MM.YYYY', timezone: 'Europe/Zurich'},
    },
    DialogService,
    /*
     {
     provide: ErrorHandler,
     useValue: Sentry.createErrorHandler({
     showDialog: true,
     }),
     },
     {
     provide: Sentry.TraceService,
     deps: [Router],
     },
     {
     provide: APP_INITIALIZER,
     useFactory: () => () => {
     },
     deps: [Sentry.TraceService],
     multi: true,
     },
     */
  ],
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass',
})
export class AppComponent implements OnInit {
  title = 'TixiPlan'

  constructor() {
  }

  ngOnInit() {
    registerLocaleData(localeDe)
  }

}
