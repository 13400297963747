@if (!isLoggedIn) {
  <ng-template pTemplate="start">
    <img src="assets/images/tixiplan.png" alt="tixiplan" class="logo"/>
    <span class="app-title">{{ title }}</span>
  </ng-template>
} @else {
  <p-menubar>
    <ng-template pTemplate="start">
      <img src="assets/images/tixiplan.png" alt="tixiplan" class="logo"/>
      <span class="app-title">{{ title }}</span>
    </ng-template>
    <ng-template pTemplate="end">
      <!-- TODO: refactor to refresh app with role spec. menu -->
      @if (hasOneOfRoles([RoleType.ADMINISTRATOR, RoleType.PLAN])) {
        <!-- Home -->
        <p-button label="Home" size="small"
                  icon="pi pi-home" iconPos="top" class="menu-item"
                  routerLink="/admin-home">
          <!--pTooltip="Home" tooltipPosition="left">-->
        </p-button>
        <!-- Spec. Menu Items -->
        <p-button label="Kalender" size="small"
                  icon="pi pi-calendar-clock" iconPos="top" class="menu-item"
                  routerLink="/admin-calendar">
          <!--pTooltip="Planungskalender" tooltipPosition="left">-->
        </p-button>
        <p-button label="Adressen" size="small"
                  icon="pi pi-address-book" iconPos="top" class="menu-item"
                  routerLink="/admin-addresses">
          <!--pTooltip="Adressverwaltung" tooltipPosition="left">-->
        </p-button>
        <p-button label="Kontakte" size="small"
                  icon="pi pi-id-card" iconPos="top" class="menu-item"
                  routerLink="/admin-contacts">
          <!--pTooltip="Kontaktverwaltung" tooltipPosition="left">-->
        </p-button>
        <p-button label="Fahrzeuge" size="small"
                  icon="pi pi-truck" iconPos="top" class="menu-item"
                  routerLink="/admin-vehicles">
          <!--pTooltip="Fahrzeugverwaltung" tooltipPosition="left">-->
        </p-button>
        <p-menu #reportMenu
                [model]="reportMenuItems"
                [popup]="true">
        </p-menu>
        <p-button label="Reports" size="small"
                  icon="pi pi-print" iconPos="top" class="menu-item"
                  (onClick)="reportMenu.toggle($event)">
          <!--pTooltip="Rapporte" tooltipPosition="left">-->
        </p-button>
        @if (hasOneOfRoles([RoleType.ADMINISTRATOR])) {
          <p-button label="Benutzer" size="small"
                    icon="pi pi-users" iconPos="top" class="menu-item"
                    routerLink="/admin-users">
            <!--pTooltip="Benutzerverwaltung" tooltipPosition="left">-->
          </p-button>
        }
      } @else if (hasOneOfRoles([RoleType.DRIVER])) {
        <!-- Home -->
        <p-button label="Meine Fahrten"
                  icon="pi pi-directions" class="menu-item"
                  routerLink="/driver-rides">
          <!--pTooltip="Meine Fahrten" tooltipPosition="left"-->
        </p-button>
        <!-- Spec. Menu Items -->
        <p-button label="Alle Fahrten"
                  icon="pi pi-arrow-right-arrow-left" class="menu-item"
                  routerLink="/driver-rides-all">
          <!--pTooltip="Alle Fahrten" tooltipPosition="left"-->
        </p-button>
        <p-button label="Fahrerliste"
                  icon="pi pi-users" class="menu-item"
                  routerLink="/drivers-all">
          <!--pTooltip="Fahrerliste" tooltipPosition="left"-->
        </p-button>
        @if (hasOneOfRoles([RoleType.RESPONSIBLE])) {
          <p-button label="Tagesverantwortung"
                    icon="pi pi-thumbs-up" class="menu-item"
                    routerLink="/admin-responsibilities">
            <!--pTooltip="Tagesverantwortung" tooltipPosition="left"-->
          </p-button>
        }
      }
      <!-- Home -->
      <!-- Common-->
      <p-menu #userMenu
              [model]="userMenuItems"
              [popup]="true">
      </p-menu>
      <p-button icon="pi pi-user" class="menu-item"
                (onClick)="userMenu.toggle($event)"
                [pTooltip]="loginName()" tooltipPosition="left">
      </p-button>
      <p-menu #barMenu
              [model]="barMenuItems"
              [popup]="true">
      </p-menu>
      <p-button icon="pi pi-bars" class="menu-item"
                (onClick)="barMenu.toggle($event)">
      </p-button>
    </ng-template>
  </p-menubar>
}
