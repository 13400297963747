<p-toast></p-toast>
<!--<p-card class="hcenter">-->
  <!--<ng-template pTemplate="header">-->
  <!--  <img src="assets/images/tixiplan.png" alt="tixiplan" class="logo"/>-->
  <!--  <span class="app-title">{{ title }}</span>-->
  <!--</ng-template>-->
  <p-dialog header="Willkommen zurück"
            [(visible)]="isDialogVisible"
            [modal]="true"
            [position]="'center'"
            closable="false"
            closeOnEscape="false"
            i18n-header
            styleClass="p-fluid">
    <ng-template pTemplate="content">
      <div class="row-spacer"></div>
      <div class="field">
        <label for="login">Login</label>
        <input #loginInput pInputText type="text" id="login"
               [(ngModel)]="loginRequest.login"
               autofocus
               placeholder="Login od. E-mail"/>
      </div>
      <div class="field">
        <label for="password">Passwort</label>
        <input pInputText type="password" id="password"
               [(ngModel)]="loginRequest.password"
               placeholder="Passwort"/>
      </div>
      <!--
      <div class="field">
        <p-button label="Passwort vergessen?"
                  [link]="true">
        </p-button>
      </div>
      <div class="field">
        <p-button label="Noch keinen Account? Heute erstellen!"
                  [link]="true">
        </p-button>
      </div>
      -->
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button label="Löschen" icon="pi pi-clear" styleClass="p-button-secondary"
                (onClick)="clear()"
                [style]="{'margin-left':'.5em'}">
      </p-button>
      <p-button label="Einloggen" icon="pi pi-user"
                (onClick)="login()">
      </p-button>
    </ng-template>
  </p-dialog>
<!--</p-card>-->
