import {bootstrapApplication} from '@angular/platform-browser'
import {appConfig} from './app/app.config'
import {AppComponent} from './app/app.component'
import "@angular/localize/init"
import "@angular/common/locales/global/de"


/*
// sentry
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

// sentry
Sentry.init({
  dsn: "https://b94eeb0f0eb4e19a47e4c71222b3b5a4@o4507300131897344.ingest.de.sentry.io/4507300143628368",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/localhost:4200/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

enableProdMode();
platformBrowserDynamic()
    // .bootstrapModule(AppModule)
    // .catch((err) => console.error(err));
*/

// bootstrap app
bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err),
)
