export const WEEKDAYS: string[] = [
  'Mo',
  'Di',
  'Mi',
  'Do',
  'Fr',
  'Sa',
  'So'
]

export enum ViewType {
  DAY = 'Day',
  DAYS = 'Days',
  WEEK = 'Week',
  WORKWEEK = 'WorkWeek',
  RESOURCE = 'Resource',
}

export enum ViewDay {
  DAY = 1,
  DAYS = 5,
  WEEK = 7,
  WORKWEEK = 5,
  RESOURCE = 1,
}

export enum ActionMode {
  CREATE = 1,
  MODIFY = 2,
  REMOVE = 3,
  REVOKE = 4,
}

export interface CalendarView {
  viewType: ViewType,
  viewDays: ViewDay,
}

export interface CalendarEventModel {
  // { id: 1, start: "2015-01-01T00:00:00", end: "2015-01-01T15:00:00", text: "Event 1", resource: "R1" }
  id: number
  start: string
  end: string
  text: string
  resourceId: string
}

export interface CalendarResourceModel {
  // { id: "R1", name: "Resource 1" }
  id: string
  name: string
}
