import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from "rxjs"
import {ServerSentEventService} from "../../../../services/src/lib/sse.service"
import {environment} from "../../../../../apps/frontend/src/environments/environment"
import {logger} from "nx/src/utils/logger"

@Component({
  selector: 'journal',
  standalone: true,
  imports: [],
  templateUrl: './journal.component.html',
  styleUrl: './journal.component.sass',
})
export class JournalComponent implements OnInit, OnDestroy {
  sseEvents: string[] = []
  private eventSubscription!: Subscription

  constructor(
      private sseSVC: ServerSentEventService,
  ) {
  }

  ngOnInit(): void {
    this.getSEESubscription()
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
    }
  }

  private getSEESubscription(){
    const apiUrl = environment.apiUrl
    this.eventSubscription = this.sseSVC.getServerSentEvent('journal')
                                 .subscribe(event => {
                                   logger.debug('journal#getSEESubscription: event=',event)
                                   this.sseEvents.push(event.data)
                                 })
  }

}
