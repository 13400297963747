import { environment } from '@env'
import { Injectable } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { HttpClient } from '@angular/common/http'
import { map, Observable } from 'rxjs'
import { PeriodModel } from '@domain/period'

@Injectable({
  providedIn: 'root'
})
export class PeriodService {
  constructor(
    private http: HttpClient
  ) { }

  getPeriodById(id: number): Observable<PeriodModel> {
    const url = `${environment.apiUrl}/periods/${id}`
    return this.http.get<PeriodModel>(url)
  }

  getPeriods(): Observable<PeriodModel[]> {
    const url = `${environment.apiUrl}/periods`
    return this.http.get<PeriodModel[]>(url)
    // return this.http.get<PeriodModel[]>(url).pipe(
    //   tap(_ => this.log('fetched heroes')),
    //   catchError(this.handleError<PeriodModel[]>('getHeroes', []))
    // )
  }

  getActivatedPeriods(): Observable<PeriodModel[]> {
    const url = `${environment.apiUrl}/periods?active=true`
    return this.http.get<PeriodModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  updatePeriod(period: PeriodModel): Observable<any> {
    const url = `${environment.apiUrl}/users/update`
    const res = this.http.put<any>(url, period).pipe(
      map(value => {
        return value
      })
    )
    logger.debug('period-service#updatePeriod: res=', res)
    return res
  }

  addPeriod(period: PeriodModel): Observable<any> {
    const url = `${environment.apiUrl}/periods`
    const res = this.http.post(url, period).pipe(
      map(value => {
        return value
      })
      // catchError(error => {
      //   logger.error(`Error: $error`)
      //   return of(null)
      // }),
    )
    logger.debug('user-service#addPeriod: res=', res)
    return res
    /*
     return this.http.post<UserModel>(url, user).pipe(
     catchError(this.handleError('addUser', user)),
     )
     */
  }

  deletePeriodsByIds(ids: number[]) {
    const url = `${environment.apiUrl}/periods/${ids}`
    logger.debug('period-service#deletePeriodsByIds: ids=', ids, ', url=', url)
    return this.http
               .delete(url)
               .pipe(
                 // catchError(this.handleError('deleteUser')),
               )
  }
}
