import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core'
import {logger} from 'nx/src/utils/logger'
import {CalendarModule} from 'primeng/calendar'
import {FormsModule} from '@angular/forms'
import {DayPilot, DayPilotCalendarComponent, DayPilotModule} from '@daypilot/daypilot-lite-angular'
import {CardModule} from 'primeng/card'
import {FloatLabelModule} from 'primeng/floatlabel'
import {DialogModule} from 'primeng/dialog'
import {InputTextModule} from 'primeng/inputtext'
import {DropdownModule} from 'primeng/dropdown'
import {CheckboxModule} from 'primeng/checkbox'
import {InputNumberModule} from 'primeng/inputnumber'
import {InputTextareaModule} from 'primeng/inputtextarea'
import {EventModel, HANDICAPS, VRideModel} from '../../../../../domain/src/lib/ride.model'
import {
  ContactAddressModel,
  ContactModel,
  OrderedAddressModel,
  PayingMethodModel,
} from '../../../../../domain/src/lib/contact.model'
import {ContactService} from '../../../../../services/src/lib/contact.service'
import {VehicleService} from '../../../../../services/src/lib/vehicle.service'
import {AddressService} from '../../../../../services/src/lib/address.service'
import {VehicleModel} from '../../../../../domain/src/lib/vehicle.model'
import {PeriodEventModel, PeriodModel} from '../../../../../domain/src/lib/period.model'
import {RideService} from '../../../../../services/src/lib/ride.service'
import {PeriodService} from '../../../../../services/src/lib/period.service'
import {ButtonGroupModule} from 'primeng/buttongroup'
import {ConfirmationService, MessageService} from 'primeng/api'
import {StorageService} from '../../../../../services/src/lib/storage.service'
import EventData = DayPilot.EventData
import {AddressModel, AddressRouteModel, AddressType} from '../../../../../domain/src/lib/address.model'
import {RadioButtonModule} from 'primeng/radiobutton'
import {ConfirmDialogModule} from 'primeng/confirmdialog'
import {ToastModule} from 'primeng/toast'
import {GeoDirectionsResult} from '../../../../../domain/src/lib/map.model'
import {ActionMode, ViewDay, ViewType, WEEKDAYS} from '../../../../../domain/src/lib/calendar.model'
import {ToolbarModule} from 'primeng/toolbar'
import {MapService} from '../../../../../services/src/lib/map.service'
import {FieldsetModule} from 'primeng/fieldset'
import {AccountingService} from '../../../../../services/src/lib/accounting.service'
import {
  convertToISODateString,
  formatToLocalISOString,
} from '../../../../../common/formatters/src/lib/format-local-date'
import {InputMaskModule} from 'primeng/inputmask'
import {DateNavigationService} from "../../../../../services/src/lib/date-navigation.service"

const DATE_FORMAT: string = 'dd.MM.yyyy'
const TIME_FORMAT: string = 'HH:mm'
const DATE_TIME_FORMAT: string = `${DATE_FORMAT} ${TIME_FORMAT}`
const ARRIVAL_HANDLING_SECS: number = 600

@Component({
  selector: 'admin-calendar',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    DayPilotModule,
    CardModule,
    FloatLabelModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    InputNumberModule,
    InputTextareaModule,
    ButtonGroupModule,
    RadioButtonModule,
    ConfirmDialogModule,
    ToastModule,
    ToolbarModule,
    FieldsetModule,
    InputMaskModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    // { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: '-1200', dateFormat: 'dd.MM.yyyy' } }
    // {provide: LOCALE_ID, useValue: 'de-CH'},
  ],
  templateUrl: './admin-calendar.component.html',
  styleUrl: './admin-calendar.component.sass',
})
export class AdminCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('pilot_calendar') pilotCalendar!: DayPilotCalendarComponent

  /*
   @HostListener('window:keydown', ['$event'])
   handleKeyEvent(event: KeyboardEvent) {
   logger.debug('admin-calendar#handleKeyEvent: key=', event.key)
   if (event.altKey) {
   switch (event.key) {
   case 'ArrowLeft':
   this.prevWeek()
   break
   case 'ArrowRight':
   this.nextWeek()
   break
   }
   }
   }

   @HostListener('window:keydown.control.n', ['$event'])
   handleKeyAdminCalendarNew(event: KeyboardEvent) {
   const now = DayPilot.Date.now().addMinutes(-120)
   this.createCalEvent(now, now.addMinutes(10), now.addMinutes(20))
   }
   */

  private readonly localizationConfig = {enable: true, disable: false}
  userId = 0
  response!: Object
  arePeriodsCollapsed = true
  actionMode!: ActionMode
  pilotDate = DayPilot.Date.today()
  // calDateString: string = this.calDate.toDate().toLocaleDateString()
  // selectedDateString = this.getIsoDate()
  today = new Date()
  selectedDate = this.today
  weekDay = this.today.getDay()
  // today = new Date(2024, 6, 22)
  // calDate = this.today
  appointmentAt!: Date
  departureAt!: Date
  arrivalAt!: Date
  periodStartAt!: Date
  startDate = new Date(`${this.today.getFullYear()}-${this.today.getMonth() + 1}-${this.today.getDay()}`)
  calendarView: string = 'day'
  showDays = 1
  isLoading = false
  isRideDialogVisible = false
  isAddressDialogVisible = false
  isDeleteDialogVisible = false
  isEditSerialDialogVisible = false
  isSerialRide = false
  doEditSerialRide = false
  doDeleteEventRides = false
  isMapButtonActive = false
  isReturnRideButtonActive = false
  isFollowUpRideButtonActive = false
  isAddrDepartureDisabled = true
  isAddrArrivalDisabled = true
  isAddAddrDepartureDisabled = true
  isAddAddrArrivalDisabled = true
  saveAddressFrom = false
  saveAddressTo = false
  selectedVehicle = 0
  selectedPayingMethod = 0
  payingMethods!: PayingMethodModel[]
  selectedHandicaps: string[] = []
  handicaps: string[] = HANDICAPS
  selectedDays: string[] = []
  calResources: DayPilot.CalendarColumnData[] = []
  calendarEvents: DayPilot.EventData[] = []
  calEvent!: DayPilot.EventData
  event!: EventModel
  eventId!: number
  rides: VRideModel[] = []
  cachedRides: VRideModel[] = []
  currentRide!: VRideModel
  backupRide!: VRideModel
  ride2!: VRideModel
  prevRide!: VRideModel
  rideId!: number
  addressRouteId!: number
  contactAddressId!: number
  contactDetailId!: number
  contacts!: ContactModel[]
  contact!: ContactModel
  vehicles!: VehicleModel[]
  addresses!: AddressModel[]
  contactAddresses!: ContactAddressModel[]
  orderedAddresses!: OrderedAddressModel[]
  address!: AddressModel
  periods!: PeriodModel[]
  periodEvents!: PeriodEventModel[]
  periodEvent!: PeriodEventModel
  deletedRides = 0

  contextMenu = new DayPilot.Menu({
    items: [
      {
        text: 'Bearbeiten',
        icon: 'pi pi-pencil',
        onClick: (args) => {
          const event = args.source
          const dp = event.calendar
          // if (this.modifyCalEvent(event.data)) {
          // dp.events.update(event)
          // }
          this.modifyCalEvent(event.data)
          // dp.events.update(event)
        },
      },
      {
        text: 'Annullieren',
        icon: 'pi pi-ban',
        onClick: (args) => {
          const event = args.source
          const dp = event.calendar
          // if (this.revokeCalEvent(args)) {
          //   dp.events.update(event)
          // }
          this.revokeCalEvent(args)
        },
      },
      {
        text: 'Löschen',
        icon: 'pi pi-trash',
        onClick: (args) => {
          const event = args.source
          const id = event.data.id
          if (this.removeCalEvent(event)) {
            logger.debug('admin-calendar#onRemove: id=', id, ', rideId=', this.rideId, ', args.source=', args.source)
            this.pilotCalendar.control.events.remove(args.source)
          }
        },
      },
    ],
  })

  pilotConfig: DayPilot.CalendarConfig = {
    viewType: 'Day',
    contextMenu: this.contextMenu,
    timeFormat: 'Clock24Hours',
    locale: 'de-ch',
    headerHeight: 30,
    cellHeight: 30,
    // scale: "cellDuration",
    heightSpec: 'BusinessHours',
    timeRangeSelectedHandling: 'Enabled',
    businessBeginsHour: 6,
    businessEndsHour: 24,
    days: 1,
    durationBarVisible: false,
    // complete: 100,
    // visible: false,
    visible: true,
    onTimeRangeSelected: this.onTimeRangeSelected.bind(this),
    // onBeforeEventRender: this.onBeforeEventRender.bind(this),
    onEventClick: this.onEventClick.bind(this),
  }

  constructor(
      private readonly confirmSvc: ConfirmationService,
      private readonly contactSvc: ContactService,
      private readonly vehicleSvc: VehicleService,
      private readonly addressSvc: AddressService,
      private readonly periodSvc: PeriodService,
      private readonly rideSvc: RideService,
      private readonly accountingSvc: AccountingService,
      private readonly storageSvc: StorageService,
      private readonly messageSvc: MessageService,
      private readonly mapSvc: MapService,
      private readonly dateNavigationSvc: DateNavigationService,
  ) {
  }

  ngOnInit() {
    this.setUserId()
    this.fetchContacts()
    // this.fetchAddresses()
    this.fetchVehicles()
    this.fetchPayingMethods()
    this.fetchPeriods()
    // this.initCalendar()
  }

  ngAfterViewInit(): void {
    // logger.debug('admin-calendar#ngAfterViewInit: calendar=', this.calendar)
    // this.clearEvent()
    // this.clearRide()
    this.clearPeriodEvent()
    this.fetchCalResources()
    this.getCalendarEvents()
    this.setCalendarView(ViewType.DAY)
  }

  checkDisabledAddresses() {
    logger.debug('admin-calendar#checkDisabledAddresses: customerId=', this.currentRide.customerId)
    if (this.currentRide.customerId) {
      this.enableAddresses()
    }
  }

  checkDisabledAddressActions(type: AddressType) {
    logger.debug('admin-calendar#checkDisabledAddressActions: customerId=', this.currentRide.customerId, ', addressType=', type)
    switch (type) {
      case AddressType.DEPARTURE: {
        // if (this.ride.departureAddressId && !isCustomerAddress) {
        if (this.currentRide.departureAddressId) {
          this.isAddAddrDepartureDisabled = false
        }
        break
      }
      case AddressType.ARRIVAL: {
        if (this.currentRide.arrivalAddressId) {
          this.isAddAddrArrivalDisabled = false
        }
        break
      }
    }
  }

  /*
   configDay: DayPilot.CalendarConfig = {
   durationBarVisible: false,
   contextMenu: this.contextMenu,
   onTimeRangeSelected: this.onTimeRangeSelected.bind(this),
   onBeforeEventRender: this.onBeforeEventRender.bind(this),
   onEventClick: this.onEventClick.bind(this),
   }
   */

  onTimeRangeSelected(args: any) {
    // const modal = await DayPilot.Modal.prompt("Neue Fahrt erstellen:", "Fahrt 1")
    this.createCalEvent(args.start, args.end)
    const dp = args.control
    dp.clearSelection()
  }

  /*
   onBeforeEventRender(args: any) {
   const dp = args.control
   }
   */

  onEventClick(args: any) {
    logger.debug('admin-calendar#onEventClick: args=', args)
    const data = args.e.data
    this.modifyCalEvent(data)
  }

  setViewMode() {
    logger.debug('admin-calendar#setViewMode: calendarView=', this.calendarView)
  }

  // ---------------------------------------------------------------------------
  private enableAddresses() {
    this.isAddrDepartureDisabled = false
    this.isAddrArrivalDisabled = false
  }

  private disableAddresses() {
    this.isAddrDepartureDisabled = true
    this.isAddrArrivalDisabled = true
  }

  private enableAddressActions() {
    this.isAddAddrDepartureDisabled = false
    this.isAddAddrArrivalDisabled = false
  }

  private disableAddressActions() {
    this.isAddAddrDepartureDisabled = true
    this.isAddAddrArrivalDisabled = true
  }

  private eventFromRide(): DayPilot.EventData {
    return {
      start: this.currentRide.departureAt ? this.currentRide.departureAt : '',
      end: this.currentRide.arrivalAt,
      id: this.currentRide.rideId,
      text: `${this.currentRide.rideId}: ${this.currentRide.customer}\n${this.currentRide.departureLocation} nach ${this.currentRide.arrivalLocation}\nFahrzeug: ${this.currentRide.vehicleId}`,
      resource: 0,
    }
  }

  private setCalendarView(viewType: ViewType) {
    let days: number
    switch (viewType) {
      case ViewType.DAY:
        days = ViewDay.DAY
        break
      case ViewType.DAYS:
        days = ViewDay.DAYS
        break
      case ViewType.WEEK:
        days = ViewDay.WEEK
        break
      case ViewType.WORKWEEK:
        days = ViewDay.WORKWEEK
        break
      case ViewType.RESOURCE:
        days = ViewDay.RESOURCE
        break
    }
    this.pilotCalendar.config.viewType = viewType
    this.pilotCalendar.config.days = days
  }

  // private addCalendarEvent(args: DayPilot.CalendarTimeRangeSelectedArgs) {
  private addCalEvent() {
    // logger.debug('admin-calendar#addCalEvent: rideId=', this.rideId, ', ride=', this.ride)
    // this.fetchCalEvent(this.rideId)
    const dp = this.pilotCalendar.control
    // const start = this.ride.departureAt !== undefined ? this.ride.departureAt : Date.toString()
    // const end = this.ride.departureAt !== undefined ? this.ride.departureAt : Date.toString()
    /*
     const event = this.eventFromRide()
     dp.events.add({
     start: start,
     end: end,
     id: this.ride.rideId,
     text: `${this.rideId}: ${this.ride.customer}\n${this.ride.departureLocation} nach ${this.ride.arrivalLocation}\nFahrzeug: ${this.ride.vehicleId}`,
     resource: 1,
     })
     dp.events.add(event)
     */
    /*
     dp.events.add(new DayPilot.Event({
     start: start,
     end: end,
     id: this.ride.rideId,
     text: `${this.rideId}: ${this.ride.customer}\n${this.ride.departureLocation} nach ${this.ride.arrivalLocation}\nFahrzeug: ${this.ride.vehicleId}`,
     resource: 1,
     }))
     */
    /*
     const event = this.eventFromRide()
     dp.events.add(new DayPilot.Event(event))
     */
    logger.debug('admin-calendar#addCalEvent: calEvent=', this.calEvent)
    dp.events.add(new DayPilot.Event(this.calEvent))
  }

  getAddresses() {
    // this.fetchAllAddresses(this.event.contactId)
    // this.fetchContactAddresses(this.currentRide.customerId)
    // this.fetchOrderedAddresses()
    // this.allAddresses = this.contactAddresses.concat(this.addresses)
    this.setContact()
    this.checkDisabledAddresses()
    // this.initHandicaps(this.currentRide.customerId)
  }

  dateSelectorChange() {
    logger.debug('admin-calendar#dateSelectorChange: monthCalDate=', this.selectedDate)
    // logger.debug('admin-calendar#dateSelectorChange: monthCalDate Year=', this.monthCalDate.getFullYear(),', Month=',this.monthCalDate.getMonth(),', Day=',this.monthCalDate.getDay())
    // yyyy-mm-ddTHH:MM:SS.000Z
    // 012345678901234567890123
    this.pilotDate = new DayPilot.Date(this.selectedDate, true)
    /*
     this.calDate = DayPilot.Date.fromYearMonthDay(
     this.monthCalDate.getFullYear(), this.monthCalDate.getMonth(), this.monthCalDate.getDay(),
     )
     */
    logger.debug('admin-calendar#dateSelectorChange: calDate=', this.pilotDate)
    // this.changeDay(0)
    this.getCalendarEvents()
    this.setCalendarView(ViewType.DAY)
    // this.calDate.toISOString().substring(0, 10)
  }

  initCalendar(): void {
    // logger.debug('admin-calendar#initCalendar: visible=', this.calendar.control.visible)
    this.pilotCalendar.config = this.pilotConfig
    this.pilotCalendar.control.visible = true
    this.pilotConfig.visible = true
  }

  showRideDialog(): void {
    this.isRideDialogVisible = true
  }

  hideRideDialog(): void {
    this.isRideDialogVisible = false
    this.hideEditSerialDialog()
  }

  showDeleteDialog(): void {
    this.isDeleteDialogVisible = true
  }

  showEditSerialDialog() {
    this.isEditSerialDialogVisible = true
  }

  hideEditSerialDialog() {
    this.isEditSerialDialogVisible = false
  }

  hideDeleteDialog(): void {
    this.isDeleteDialogVisible = false
  }

  onEditSingleRide() {
    this.doEditSerialRide = false
    logger.debug('admin-calendar#onEditSerialRide: doEditSerialRide=', this.doEditSerialRide)
    this.showRideDialog()
  }

  onEditSerialRide() {
    this.doEditSerialRide = true
    logger.debug('admin-calendar#onEditSerialRide: doEditSerialRide=', this.doEditSerialRide)
    this.showRideDialog()
  }

  checkSerialRide() {
    this.isSerialRide = (this.currentRide.periodEventId != null)
  }

  onDeleteSingleRide() {
    if (this.doDeleteEventRides) {
      this.deleteEventRides()
    } else {
      this.deleteRides()
    }
    this.hideDeleteDialog()
  }

  onDeleteSerialRide() {
    if (this.doDeleteEventRides) {
      this.deleteSerialEventRides()
    } else {
      this.deleteSerialRide()
    }
    this.hideDeleteDialog()
  }

  adjustDates() {
    if (this.arrivalAt <= this.departureAt) {
      if (this.currentRide.duration) {
        this.arrivalAt = new Date(this.departureAt.getTime() + this.currentRide.duration * 1000)
      } else {
        this.arrivalAt = new Date(this.departureAt.getTime() + 300_000)
      }
    }
    if (this.appointmentAt && this.arrivalAt < this.appointmentAt) {
      this.appointmentAt = this.arrivalAt
    }
  }

  setAppointment() {
    logger.debug('admin-calendar#setAppointmentAt: this.ride.appointmentAt=', this.currentRide.appointmentAt)
    var appointmentAt: string = ''
    if (this.currentRide.appointmentAtUnix !== undefined) {
      // appointmentAt = Date.parse(this.ride.appointmentAt) //+ ARRIVAL_HANDLING_SECS
      appointmentAt = new Date(this.currentRide.appointmentAtUnix).toLocaleString()
    }
    logger.debug('admin-calendar#setAppointmentAt: appointmentAt=', appointmentAt)
  }


  saveCalEvents() {
    logger.debug('admin-calendar#saveCalEvents: selectedDays=', this.selectedDays)
    logger.debug('admin-calendar#saveCalEvents: currentRide=', this.currentRide)
    logger.debug('admin-calendar#saveCalEvents: actionMode=', this.actionMode)
    // return
    this.cacheCurrentRide()
    // this.syncDateTimes()
    this.hideRideDialog()

    if (this.actionMode === ActionMode.CREATE) {
      // this.addRide()
      // this.addRideAndGetEvent().then(value => {
      this.addRidesAndGetEvent()
      // this.fetchVRide(this.rideId)
    } else if (this.actionMode === ActionMode.MODIFY) {
      this.updateRide()
    }
    // const day = this.changeDay(0)
    // this.getCalendarEvents(day)
    // this.getCalendarEvents(this.calDate.toLocaleDateString())
  }

  /*
   error: (err) => {
   console.error('saveRide: error=', err)
   this.messageSVC.add({
   severity: 'error',
   summary: 'Fehler beim Speichern',
   detail: "Fahrt konnte nicht erstellt werden",
   life: 5000,
   })
   },
   complete: () => {
   if (this.ride.id == -1) {
   console.error('saveRide: complete=', this.ride.id)
   this.messageSVC.add({
   severity: 'error', summary: 'Fehler beim Speichern', detail: 'Fahrt schon vorhanden', life: 5000,
   })
   } else {
   // this.
   this.messageSVC.add({
   severity: 'success', summary: 'Erfolg', detail: 'Eintrag erstellt', life: 3000,
   })
   this.rides.push(this.ride)
   }
   },
   },
   )
   }
   */

  saveEvent() {
    this.saveHandicaps()
    logger.debug('admin-calendar#saveEvent: event=', this.event)
    /*
     const event:EventModel = <EventModel>{
     // id: number
     contactId:this.contact.id,
     periodEventId: this.periodEvent.id,
     isForeignRide: this.isFollowUpRideButtonActive
     doSendInvoice: this.
     comments:
     isInvoiced: boolean
     handicaps: this.handicaps
     flatCost: number
     isFreeOfCharge: boolean
     addEventWaitTime: number
     moneyPending: number
     */
  }

  private setUserId() {
    this.userId = this.storageSvc.getLoginUserId()
    logger.debug('admin-calendar#setUserId: userId=', this.userId)
  }

  private initRide(departureAt: DayPilot.Date | string, arrivalAt: DayPilot.Date | string, appointmentAt?: DayPilot.Date) {
    logger.debug('admin-calendar#initRide: departureAt=', departureAt, ', arrivalAt=', arrivalAt)
    if (this.actionMode === ActionMode.CREATE) {
      this.currentRide = <VRideModel>{
        createdById: this.userId,
        isEventValidated: true,
      }
      if (appointmentAt) {
        this.appointmentAt = this.toDate(appointmentAt)
        // } else {
        //   this.appointmentAt = this.toDate(arrivalAt) //.toString(DATE_TIME_FORMAT)
      }
      this.departureAt = this.toDate(departureAt) //.toString(DATE_TIME_FORMAT)
      this.arrivalAt = this.toDate(arrivalAt) //.toString(DATE_TIME_FORMAT)
      this.periodEvent.endOn = this.initPeriod()
    }
  }

  isRideValid(): boolean {
    // logger.debug('admin-calendar#isRideValid: this.ride=', this.ride)
    return (
        this.currentRide.customerId != null
        && this.currentRide.departureAddressId != null
        && this.currentRide.arrivalAddressId != null
    )
  }

  addAddressToContact(type: AddressType) {
    logger.debug('admin-calendar#addAddressToContact: type=', type)
    const contactAddress = <ContactAddressModel>{
      contactId: this.contact.id,
      addressId: this.address.id,
    }
    this.isLoading = true
    // this.addressSvc.addContactAddress(contactAddress).subscribe({
    this.contactSvc.addContactAddress(contactAddress).subscribe({
      next: (value) => {
        this.contactAddressId = value
      },
      complete: () => {
        this.isLoading = false
        logger.debug('admin-calendar#addAddressToContact: routeId=', this.addressRouteId)
      },
    })
  }

  addRoute() {
    logger.debug('admin-calendar#addRoute: arrivalAddressId=', this.currentRide.arrivalAddressId, ', departureAddressId=', this.currentRide.departureAddressId)
    this.getDistAndDuration()
    const addressRoute = <AddressRouteModel>{
      id: 0,
      address1Id: this.currentRide.arrivalAddressId,
      address2Id: this.currentRide.departureAddressId,
    }
    this.addressSvc.addRoute(addressRoute).subscribe({
      next: (value) => {
        this.addressRouteId = value
      },
      complete: () => {
        logger.debug('admin-calendar#addRoute: routeId=', this.addressRouteId)
      },
    })
  }

  private getDistAndDuration() {
    logger.debug('admin-addresses#getDistAndDuration: departureAddress=', this.currentRide.departureAddress)
    logger.debug('admin-addresses#getDistAndDuration: arrivalAddress=', this.currentRide.arrivalAddress)
    this.isLoading = true
    this.mapSvc.coordinates(this.currentRide.departureAddress, this.currentRide.arrivalAddress, undefined).subscribe({
      next: (value: GeoDirectionsResult) => {
        this.address.journeyDistance = Math.round(value.distance / 1000)
        this.address.journeyDuration = value.duration
      },
      complete: () => {
        logger.debug('admin-addresses#getDistAndDuration: distance=', this.address.journeyDistance)
        logger.debug('admin-addresses#getDistAndDuration: duration=', this.address.journeyDuration)
        this.isLoading = false
      },
    })
  }

  /*
   //
   // load data
   //
   function loadEvents() {
   DayPilot.request("events.json.php?action=get",
   function(result) { // success
   loadVehicleList()
   var response = eval("(" + result.responseText + ")")
   dp.events.list = [] // clean events
   benchmark('BEGIN')
   $.each(response.data.events, function(i, value) {
   e = new DayPilot.Event(value)
   e.client.cssClass(e.data.class)
   dp.events.add(e)
   benchmark('create event')
   });
   benchmark('END')
   },
   { 'start': dp.visibleStart(), 'end': dp.visibleEnd() },
   function(req) {  // error
   message('error',"Beim Laden des Kalenders ist ein Fehler aufgetreten");
   document.location = "/calendar"
   }
   );
   }
   */

  private createCalEvent(departureAt: DayPilot.Date, arrivalAt: DayPilot.Date, appointmentAt?: DayPilot.Date) {
    logger.debug('admin-calendar#createCalEvent: departureAt=', departureAt, ', arrivalAt=', arrivalAt)
    this.actionMode = ActionMode.CREATE
    // this.clearRide()
    // this.initRide(departureAt, arrivalAt, appointmentAt)
    this.initRide(departureAt, arrivalAt)
    this.disableAddresses()
    logger.debug('admin-calendar#createCalEvent: ride=', this.currentRide)
    this.showRideDialog()
  }

  // modifyCalEvent(args: DayPilot.MenuItemClickArgs): boolean {
  private modifyCalEvent(data: DayPilot.EventData) {
    logger.debug('admin-calendar#modifyCalEvent: data=', data)
    // logger.debug('admin-calendar#modifyCalEvent: typeof data.id=', typeof data.id)
    this.rideId = typeof data.id === 'string' ? parseInt(data.id) : data.id
    this.actionMode = ActionMode.MODIFY
    logger.debug('admin-calendar#modifyCalEvent: rideId', this.rideId)
    this.editVRide()
  }

  /*
   private addRide(): boolean {
   logger.debug('admin-calendar#addRide: ride=', this.ride)
   var success = false
   this.loading = true
   this.rideSvc.add(this.ride).subscribe({
   next: (value) => {
   logger.debug('admin-calendar#addRide: value=', value)
   this.rideId = parseInt(value.toString())
   },
   complete: () => {
   logger.debug('admin-calendar#addRide: rideId=', this.rideId)
   success = true
   // this.addresses = this.addresses.filter((val) => val.id !== rowData.id)
   this.messageSVC.add({
   severity: 'success', summary: 'Erfolgreich', detail: 'Eintrag erstellt', life: 3000,
   })
   // this.address = <AddressModel>{}
   },
   error: (ex) => {
   logger.debug('admin-calendar#addRide: ex=', ex)
   success = false
   this.messageSVC.add({
   severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht erstellt werden!', life: 5000,
   })
   },
   })
   this.loading = false
   return success
   }
   */

  /*
   private addRideAndGetEvent(): boolean {
   logger.debug('admin-calendar#addRideAndGetEvent: ride=', this.ride)
   let success = false
   this.loading = true
   this.rideSvc.addRideAndGetEvent(this.ride).subscribe({
   next: (value) => {
   logger.debug('admin-calendar#addRideAndGetEvent: value=', value)
   this.calEvent=value[0]
   this.rideId = value[0].id
   },
   complete: () => {
   logger.debug('admin-calendar#addRideAndGetEvent: calEvent=', this.calEvent)
   logger.debug('admin-calendar#addRideAndGetEvent=', this.rideId)
   success = true
   // this.addresses = this.addresses.filter((val) => val.id !== rowData.id)
   this.messageSVC.add({
   severity: 'success', summary: 'Erfolgreich', detail: 'Eintrag erstellt', life: 3000,
   })
   // this.address = <AddressModel>{}
   },
   error: (ex) => {
   logger.debug('admin-calendar#addRideAndGetEvent: ex=', ex)
   success = false
   this.messageSVC.add({
   severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht erstellt werden!', life: 5000,
   })
   },
   })
   this.loading = false
   return success
   }
   */

  /*
   private addRideAndGetEvent(): Promise<any> {
   logger.debug('admin-calendar#addRideAndGetEvent: ride=', this.ride)
   let success = false
   this.loading = true
   const response = this.rideSvc.execAddRideAndGetEvent(this.ride)
   logger.debug('admin-calendar#addRideAndGetEvent: response=', response)
   response.then(value => {
   this.calEvent = value[0]
   },
   )
   // this.rideId = value[0].id
   // let then = response.then(value => this.calEvent = value)
   logger.debug('admin-calendar#addRideAndGetEvent: calEvent=', this.calEvent)
   this.loading = false
   // return success
   return response
   }
   */

  private addRidesAndGetEvent() {
    logger.debug('admin-calendar#addRidesAndGetEvent: rides=', this.cachedRides)
    this.isLoading = true
    this.rideSvc.addRidesAndGetEvent(this.cachedRides).subscribe({
      next: (value) => {
        this.response = value
        // this.calEvent = response[0]
      },
      complete: () => {
        this.isLoading = false
        // this.addCalEvent()
        logger.debug('admin-calendar#addRidesAndGetEvent: response=', this.response)
        this.getCalendarEvents()
        logger.debug('admin-calendar#addRideAndGetEvent: calEvent=', this.calEvent)
      },
    })
  }

  /*
   private async addRideAndGetEvent(): Promise<any> {
   logger.debug('admin-calendar#addRideAndGetEvent: ride=', this.currentRide)
   let success = false
   this.isLoading = true
   const response = await this.rideSvc.addRideAndGetEvent(this.currentRide)
   logger.debug('admin-calendar#addRideAndGetEvent: response=', response)
   this.calEvent = response[0]
   // this.rideId = value[0].id
   // let then = response.then(value => this.calEvent = value)
   logger.debug('admin-calendar#addRideAndGetEvent: calEvent=', this.calEvent)
   this.isLoading = false
   // return success
   return response
   }
   */

  /*
   private async deleteRide() {
   this.isLoading = true
   const response = this.rideSvc.deleteRidesByIds(this.deleteReturnRide, [this.rideId]).subscribe()
   logger.debug('admin-calendar#deleteRide: calEvent=', this.rideId)
   logger.debug('admin-calendar#deleteRide: response=', response)
   // return success
   return response
   }
   */

  private deleteRides() {
    this.isLoading = true
    logger.debug('admin-calendar#deleteRides: rideId=', this.rideId)
    this.rideSvc.deleteRidesByIds([this.rideId]).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        logger.debug('admin-calendar#deleteRide: response=', this.response)
        this.getCalendarEvents()
      },
    })
  }

  private deleteEventRides() {
    this.isLoading = true
    logger.debug('admin-calendar#deleteEventRides: eventId=', this.eventId)
    this.rideSvc.deleteRidesByEventIds([this.eventId]).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        this.getCalendarEvents()
        logger.debug('admin-calendar#deleteEventRide: response=', this.response)
      },
    })
  }

  private deleteSerialRide() {
    this.isLoading = true
    logger.debug('admin-calendar#deleteSerialRide: rideId=', this.rideId)
    this.rideSvc.deleteSerialRidesByIds([this.rideId]).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        this.getCalendarEvents()
        logger.debug('admin-calendar#deleteSerialRide: response=', this.response)
      },
    })
  }

  private deleteSerialEventRides() {
    this.isLoading = true
    logger.debug('admin-calendar#deleteSerialEventRides: eventId=', this.eventId)
    this.rideSvc.deleteSerialRidesByEventIds([this.eventId]).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        this.getCalendarEvents()
        logger.debug('admin-calendar#deleteSerialRide: response=', this.response)
      },
    })
  }

  /*
   private deleteRide() {
   let success = false
   let deleted: number = 0
   this.loading = true
   this.rideSvc.delete([this.rideId]).subscribe({
   next: (value) => {
   // deleted=value.toInt()
   deleted = parseInt(value.toString())
   },
   complete: () => {
   logger.debug('admin-calendar#deleteRide: deleted=', deleted)
   if (deleted > 0) {
   success = true
   this.messageSVC.add({
   severity: 'success', summary: 'Erfolgreich', detail: 'Eintrag gelöscht', life: 3000,
   })
   } else {
   success = false
   this.messageSVC.add({
   severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht gelöscht werden!', life: 5000,
   })
   }
   },
   error: (ex) => {
   logger.debug('admin-calendar#deleteRide: ex=', ex)
   success = false
   this.messageSVC.add({
   severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht gelöscht werden!', life: 5000,
   })
   },
   })
   this.loading = false
   return success
   }
   */

  private revokeEventById() {
    this.isLoading = true
    logger.debug('admin-calendar#revokeEventById: eventId=', this.eventId)
    this.rideSvc.revokeEventById(this.eventId).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        logger.debug('admin-calendar#revokeEventById: res=', this.response)
        this.getCalendarEvents()
        /*
         if (this.response> 0) {
         this.messageSVC.add({
         severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht modifizert werden!', life: 5000
         })
         } else {
         this.messageSVC.add({
         severity: 'success', summary: 'Erfolgreich', detail: 'Eintrag modifizert', life: 3000
         })
         }
         */
      },
    })
  }

  private updateRide() {
    logger.debug('admin-calendar#updateRide: ride=', this.currentRide)
    logger.debug('admin-calendar#updateSingleRide: ')
    this.isLoading = true
    this.rideSvc.updateRide(this.currentRide, this.doEditSerialRide).subscribe({
      next: (value) => {
        this.response = value
      },
      complete: () => {
        this.isLoading = false
        this.getCalendarEvents()
        logger.debug('admin-calendar#updateSingleRide: res=', this.response)
        /*
         if (this.response> 0) {
         this.messageSVC.add({
         severity: 'error', summary: 'Fehler', detail: 'Eintrag konnte nicht modifizert werden!', life: 5000
         })
         } else {
         this.messageSVC.add({
         severity: 'success', summary: 'Erfolgreich', detail: 'Eintrag modifizert', life: 3000
         })
         }
         */
      },
    })
  }


  /*
   hasSerialDataChanged(): boolean {
   logger.debug('admin-calendar#hasSerialDataChanged: ride=', this.currentRide)
   logger.debug('admin-calendar#hasSerialDataChanged: backup=', this.backupRide)
   if (this.currentRide !== this.backupRide) {
   if (this.currentRide.periodEventId) {}

   }
   return false
   }
   */

  setRide(id: number) {
    logger.debug('admin-calendar#setRide: rides=', this.rides)
    this.currentRide = this.rides.filter(value => value.rideId == id)[0]
    logger.debug('admin-calendar#setRide: rideId=', this.rideId, ', ride=', this.currentRide)
    const event = this.calendarEvents.filter(value => value.id == this.currentRide.eventId)[0]
    this.event = <EventModel>{
      id: this.currentRide.eventId,
      flatCost: 0,
      isForeignRide: false,
      isInvoiced: false,
      isFreeOfCharge: false,
    }
  }

  /*
   private fetchRide(id: number) {
   this.loading = true
   this.rideSvc.get(id).subscribe({
   next: (values) => {
   this.ride = values[0]
   },
   complete: () => {
   logger.debug('admin-calendar#fetchRide: ride=', this.ride)
   },
   })
   this.loading = false
   }
   */

  private editVRide() {
    logger.debug('admin-calendar#editVRide: rideId=', this.rideId)
    this.isLoading = true
    this.rideSvc.getRidesById(this.rideId).subscribe({
      next: (values) => {
        this.currentRide = values[0]
      },
      complete: () => {
        this.isLoading = false
        this.backupRide = this.currentRide
        // this.initPeriodWeekdays()
        logger.debug('admin-calendar#editVRide: ride=', this.currentRide)
        this.checkSerialRide()
        // this.fetchContactAddresses(this.currentRide.customerId)
        this.fetchOrderedAddresses()
        // this.enableAddresses()
        logger.debug('admin-calendar#editVRide: ride.appointmentAt=', this.currentRide.appointmentAt)
        if (this.currentRide.appointmentAt) {
          logger.debug('admin-calendar#editVRide: convert appointmentAt!')
          this.appointmentAt = this.toDate(this.currentRide.appointmentAt)
        }
        logger.debug('admin-calendar#editVRide: ride.departureAt=', this.currentRide.departureAt)
        if (this.currentRide.departureAt) {
          this.departureAt = this.toDate(this.currentRide.departureAt)
          logger.debug('admin-calendar#editVRide: departureAt=', this.departureAt)
        }
        logger.debug('admin-calendar#editVRide: ride.arrivalAt=', this.currentRide.arrivalAt)
        if (this.currentRide.arrivalAt) {
          this.arrivalAt = this.toDate(this.currentRide.arrivalAt)
          logger.debug('admin-calendar#editVRide: arrivalAt=', this.arrivalAt)
        }
        if (this.currentRide.departureAddressId) {
          // const d = this.currentRide.departureAddressId
          // this.currentRide.departureAddressId = 0
          // this.currentRide.departureAddressId = d
          logger.debug('admin-calendar#editVRide: departureAddressId=', this.currentRide.departureAddressId)
        }
        if (this.currentRide.vehicleId) {
          this.selectedVehicle = this.currentRide.vehicleId
        }
        this.setContact()
        this.setPeriodData()
        // if (doShowRideDialog) {
        if (this.isSerialRide) {
          this.showEditSerialDialog()
        } else {
          this.showRideDialog()
        }
      },
    })
  }

  /*
   getDistAndDuration() {
   const origin = `${this.address.street} ${this.address.zip} ${this.address.location}`
   const destination = `${this.address.street} ${this.address.zip} ${this.address.location}`
   logger.debug('admin-calendar#getDistAndDuration: origin=', origin)
   logger.debug('admin-calendar#getDistAndDuration: destination=', destination)
   this.isLoading = true
   this.geoApiSVC.coordinates(origin, destination, undefined).subscribe({
   next: (value: GeoDirectionsResult) => {
   this.address.journeyDistance = Math.round(value.distance / 1000)
   this.address.journeyDuration = value.duration
   },
   complete: () => {
   logger.debug('admin-calendar#getDistAndDuration: distance=', this.address.journeyDistance)
   logger.debug('admin-calendar#getDistAndDuration: duration=', this.address.journeyDuration)
   this.isLoading = false
   },
   })
   }
   */

  private toDate(date: string | DayPilot.Date): Date {
    // this.arrivalAt = arrivalAt.toString(DATE_TIME_FORMAT)
    if (typeof date === 'string') {
      return new Date(date)
    }
    // return date.toDate()
    return date.toDateLocal()
  }

  private fetchCalEvent(id: number) {
    this.isLoading = true
    this.rideSvc.getCalEventsById(id).subscribe({
      next: (values) => {
        this.calEvent = values[0]
      },
      complete: () => {
        logger.debug('admin-calendar#fetchCalEvent: calEvent=', this.calEvent)
        this.isLoading = false
      },
    })
  }

  revokeCalEvent(args: DayPilot.MenuItemClickArgs) {
    logger.debug('admin-calendar#revokeCalEvent: args=', args)
    this.actionMode = ActionMode.REVOKE
    const item = args.source.data as EventData
    let success = false
    logger.debug('admin-calendar#revokeCalEvent: ride=', this.currentRide)
    this.rideId = args.source.data.id
    this.eventId = args.source.data.eventId

    this.confirmSvc.confirm({
      message: `Eintrag ${this.rideId} annullieren?`,
      header: 'Bestätigung',
      acceptLabel: 'Annullieren',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Abbrechen',
      rejectButtonStyleClass: 'p-button-secondary',
      defaultFocus: 'reject',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.fetchVRide(this.rideId)
        // this.ride.isEventValidated = false
        // this.updateRide()
        this.revokeEventById()
        logger.debug('admin-calendar#revokeCalEvent: response=', this.response, ', typeOf=', typeof this.response)
        success = true
      },
    })
    return success
  }

  // removeCalEvent(args: DayPilot.MenuItemClickArgs) {
  // removeCalEvent(id: number) {
  /*
   removeCalEvent(calEvent: DayPilot.Event) {
   logger.debug('admin-calendar#removeRide: calEvent=', calEvent)
   this.actionMode = ActionMode.REMOVE
   this.rideId = calEvent.data.id
   // this.rideId = id
   let success = false
   this.confirmSVC.confirm({
   message: `Eintrag ${this.rideId} löschen?`,
   header: 'Bestätigung',
   acceptLabel: 'Löschen',
   acceptButtonStyleClass: 'p-button-danger',
   rejectLabel: 'Abbrechen',
   rejectButtonStyleClass: 'p-button-secondary',
   defaultFocus: 'reject',
   icon: 'pi pi-exclamation-triangle',
   accept: () => {
   this.deleteRide().then(value => {
   logger.debug('admin-calendar#removeCalEvent: value=', value)
   this.dayCalendar.control.events.remove(calEvent)
   })
   // if (this.deleteRide()) {
   // logger.debug('admin-calendar#removeRide: id=', id)
   // this.dayCalendar.control.events.remove(id)
   // }
   }
   })
   return success
   }
   */

  removeCalEvent(calEvent: DayPilot.Event) {
    logger.debug('admin-calendar#removeRide: calEvent=', calEvent)
    this.actionMode = ActionMode.REMOVE
    this.rideId = calEvent.data.id
    this.eventId = calEvent.data.eventId
    // this.rideId = id
    let success = false
    // this.fetchCalEvent(this.rideId)
    this.rideSvc.getRidesById(this.rideId).subscribe({
      next: (values) => {
        this.currentRide = values[0]
      },
      complete: () => {
        this.isLoading = false
        logger.debug('admin-calendar#removeRide: ride=', this.currentRide)
        this.showDeleteDialog()
      },
    })
    // logger.debug('admin-calendar#removeCalEvent: value=', value)
    // this.dayCalendar.control.events.remove(calEvent)
    return success
  }


  private initPeriod() {
    return `31.12.${this.today.getFullYear()}`
  }

  /*
   private initPeriodWeekdays() {
   logger.debug('admin-calendar#initPeriodWeekdays: today.day=', this.today.getDay())
   let days = this.weekDays
   // this.selectedDays = this.days.filter(value => days.includes(value.value))
   }
   */

  private clearAddress() {
    this.address = <AddressModel>{
      id: 0,
      addressTypeId: 0,
      description: '',
      street: '',
      location: '',
      isActivated: false,
      isJourneyValid: true,
      journeyDistance: 0,
      journeyDuration: 0,
      placeId: '',
      latitude: 0,
      longitude: 0,
    }
  }

  newAddress() {
    logger.debug('admin-calendar#newAddress')
    this.isMapButtonActive = false
    this.clearAddress()
    this.isAddressDialogVisible = true
  }

  hideAddressDialog() {
    this.isAddressDialogVisible = false
  }

  showRoute() {
    logger.debug('admin-calendar#showRoute')
  }

  saveAddress() {
    logger.debug('admin-calendar#saveAddress')
  }

  clearPeriodEvent() {
    this.periodEvent = <PeriodEventModel>{}
  }

  createReturnRide() {
    logger.log('admin-calendar#createReturnRide: currentRide=', this.currentRide)
    this.initReturnRide()
  }

  createFollowUpRide() {
    logger.log('admin-calendar#createFollowUpRide: currentRide=', this.currentRide)
    this.initFollowUpRide()
  }

  private initReturnRide() {
    logger.debug('admin-calendar#initReturnRide=', this.currentRide)
    this.cacheCurrentRide()
    const tempRide = this.currentRide
    this.currentRide = <VRideModel>{
      customerId: tempRide.customerId,
      departureAddressId: tempRide.arrivalAddressId,
      arrivalAddressId: tempRide.departureAddressId,
      departureAt: tempRide.arrivalAt,
    }
    logger.debug('admin-calendar#initReturnRide=', this.currentRide)
  }

  private initFollowUpRide() {
    this.cacheCurrentRide()
    this.currentRide = <VRideModel>{
      customerId: this.currentRide.customerId,
      departureAddressId: this.currentRide.arrivalAddressId,
      departureAt: this.currentRide.arrivalAt,
    }
  }

  private cacheCurrentRide() {
    logger.debug('admin-calendar#cacheCurrentRide start: cachedRides.length=', this.cachedRides.length)
    // logger.debug('admin-calendar#cacheCurrentRide: currentRide=', this.currentRide)
    this.cachedRides.push(this.currentRide)
    this.syncDateTimes()
    logger.debug('admin-calendar#cacheCurrentRide finish: cachedRides.length=', this.cachedRides.length)
  }

  private syncDateTimes() {
    const index = this.cachedRides.length - 1
    logger.debug('admin-calendar#syncDateTimes: cachedRides[index]=', this.cachedRides[index])
    if (this.appointmentAt) {
      this.cachedRides[index].appointmentAt = formatToLocalISOString(this.appointmentAt)
    }
    this.cachedRides[index].departureAt = formatToLocalISOString(this.departureAt)
    this.cachedRides[index].arrivalAt = formatToLocalISOString(this.arrivalAt)
  }

  showConfirm(title: string) {
    // logger.debug('admin-calendar#showConfirm: title=', title)
  }

  private saveHandicaps() {
    // const handicaps = this.selectedHandicaps.map(value => value.value).join(',')
    this.event.handicaps = this.selectedHandicaps
    logger.debug('admin-calendar#saveHandicaps: handicaps=', this.selectedHandicaps)
  }

  private initHandicaps() {
    this.selectedHandicaps = this.contact.handicaps
    logger.debug('admin-calendar#initHandicaps: selectedHandicaps=', this.selectedHandicaps)
  }

  /*
   private mapToSelectedDay(dayKey: string): string {
   const weekday = WEEKDAY_MAP.find(w => w.key === dayKey)
   return weekday ? weekday.value : ''
   }
   */

  /*
   private initPeriodDays(): void {
   logger.debug('admin-calendar#initPeriodDays: currentRide=', this.currentRide)
   this.selectedDays = this.currentRide.periodDays.map(this.mapToSelectedDay)
   logger.debug('admin-calendar#initPeriodDays: selectedDays=', this.selectedDays)
   }
   */

  onChangeVehicle() {
    this.currentRide.vehicleId = this.selectedVehicle
  }

  private initVehicle() {
    if (!this.selectedVehicle || this.selectedVehicle < 1) {
      this.selectedVehicle = this.contact.preferredVehicleId
    }
  }

  private initPayingMethod() {
    this.selectedPayingMethod = this.contact.preferredPayingMethodId
  }


  private setPeriodData() {
    logger.debug('admin-calendar#setPeriodData: this.currentRide=', this.currentRide)
    if (this.currentRide.periodEventId != null) {
      this.selectedDays = this.currentRide.periodDays
      this.periodStartAt = this.toDate(this.currentRide.periodStartAt)
      this.arePeriodsCollapsed = false
      logger.debug('admin-calendar#setPeriodData: selectedDays=', this.selectedDays, ', periodStartAt=', this.periodStartAt, ', periodFinishOn=', this.periodFinishOn)
    } else {
      this.arePeriodsCollapsed = true
      const currentYear = new Date().getFullYear()
      this.currentRide.periodFinishOn = `${currentYear}-12-31`
      logger.debug('admin-calendar#setPeriodData: this is not a serial ride')
    }
  }

  get periodFinishOn(): string {
    return this.currentRide.periodFinishOn ? this.formatDate(this.currentRide.periodFinishOn) : ''
  }

  set periodFinishOn(value: string) {
    this.currentRide.periodFinishOn = this.parseDate(value)
  }

  formatDate(value: string): string {
    const [year, month, day] = value.split('-')
    return `${day}.${month}.${year}`
  }

  parseDate(value: string): string {
    if (!value) {
      return ''
    }
    const [day, month, year] = value.split('.')
    return `${year}-${month}-${day}`
  }

  setContact() {
    // this.contact
    logger.debug('admin-calendar#setContact: id=', this.currentRide.customerId, ', contacts=', this.contacts)
    const contact = this.contacts.find(value => value.id == this.currentRide.customerId)
    logger.debug('admin-calendar#setContact: contact=', contact)
    if (contact) {
      this.contact = contact
    } else {
      this.contact = <ContactModel>{}
    }
    this.fetchOrderedAddresses()
    this.initHandicaps()
    this.initVehicle()
    this.initPayingMethod()
  }

  showDate(date: string | undefined) {
    logger.debug('admin-calendar#showDate: date=', date)
  }

  private fetchContacts() {
    logger.debug('admin-calendar#fetchContacts')
    this.isLoading = true
    this.contactSvc.getContacts().subscribe({
      next: (values) => {
        this.contacts = values
      },
      complete: () => {
        this.isLoading = false
      },
    })
  }

  private fetchVehicles() {
    this.isLoading = true
    this.vehicleSvc.getActiveVehicles().subscribe({
      next: (values) => {
        this.vehicles = values
      },
      complete: () => {
        this.isLoading = false
      },
    })
  }

  private fetchAddresses() {
    this.isLoading = true
    this.addressSvc.getAddresses().subscribe({
      next: (values) => {
        this.addresses = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchAddresses: addresses=', this.addresses)
        this.isLoading = false
      },
    })
  }

  private fetchOrderedAddresses() {
    this.isLoading = true
    this.contactSvc.getOrderedAddressesByContactId(this.currentRide.customerId).subscribe({
      next: (values) => {
        this.orderedAddresses = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchOrderedAddresses: addresses=', this.orderedAddresses)
        this.isLoading = false
        this.enableAddresses()
      },
    })
  }

  private fetchContactAddresses(contactId: number) {
    this.isLoading = true
    this.contactSvc.getContactAddressesByContactId(contactId).subscribe({
      next: (values) => {
        this.contactAddresses = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchContactAddresses: addresses=', this.contactAddresses)
        this.isLoading = false
      },
    })
  }

  private fetchPeriods() {
    this.isLoading = true
    this.periodSvc.getActivatedPeriods().subscribe({
      next: (values) => {
        this.periods = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchPeriods: periods=', this.periods)
        this.isLoading = false
      },
    })
  }

  private fetchRides(fromDay: string) {
    this.isLoading = true
    // this.rideSvc.getFromTo(fromDay, toDay).subscribe(values => {
    //   this.rides = values
    // })
    this.rideSvc.getVRidesFromDay(fromDay, this.showDays).subscribe({
      next: (values) => {
        this.rides = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchRides: rides=', this.rides)
        this.isLoading = false
      },
    })
  }

  /*
   private getIsoDate() {
   return this.pilotDate.toDate().toISOString().substring(0, 10)
   }
   */


  private getCalendarEvents() {
    const isoDate = convertToISODateString(this.selectedDate)
    this.isLoading = true
    this.rideSvc.getCalEventsFrom(isoDate, this.showDays).subscribe({
      next: (values) => {
        this.calendarEvents = [...values]
      },
      complete: () => {
        this.isLoading = false
        this.pilotCalendar.config.startDate = this.convertToPilotDate(this.selectedDate)
        logger.debug('admin-calendar#getCalendarEvents: pilotCalendar.startDate=', this.pilotCalendar.config.startDate)
        logger.debug('admin-calendar#getCalendarEvents: calendarEvents=', this.calendarEvents)
      },
    })
  }

  private fetchCalResources() {
    this.isLoading = true
    this.vehicleSvc.getCalResources().subscribe({
      next: (values) => {
        this.calResources = values
        this.pilotCalendar.control.columns.list = values
      },
      complete: () => {
        logger.debug('admin-calendar#fetchCalResources: resources=', this.calResources)
        logger.debug('admin-calendar#fetchCalResources: resources.list=', this.pilotCalendar.control.columns.list)
        this.isLoading = false
      },
    })
  }

  private fetchPayingMethods() {
    this.isLoading = true
    this.accountingSvc.getPayingMethods().subscribe({
      next: (values) => {
        this.payingMethods = values
      },
      complete: () => {
        this.isLoading = true
      },
    })

  }

  toDay() {
    this.selectedDate = new Date()
    this.getCalendarEvents()
    this.setCalendarView(ViewType.DAY)
  }

  prevDay() {
    this.changeDay(-1)
    this.setCalendarView(ViewType.DAY)
  }

  nextDay() {
    this.changeDay(1)
    this.setCalendarView(ViewType.DAY)
  }

  prevWeek() {
    this.changeDay(-7)
  }

  nextWeek() {
    this.changeDay(7)
  }

  prevMonth() {
    this.changeMonth(-1)
  }

  nextMonth() {
    this.changeMonth(1)
  }

  private changeDay(days: number) {
    this.selectedDate = this.dateNavigationSvc.changeDay(this.selectedDate, days)
    logger.debug('admin-calendar#changeDay: pilotDate=', this.pilotDate)
    this.getCalendarEvents()
  }

  private changeMonth(months: number) {
    this.selectedDate = this.dateNavigationSvc.changeMonth(this.selectedDate, months)
    this.getCalendarEvents()
  }

  onDateSelectorChange() {
    if (this.selectedDate) {
      logger.debug('admin-calendar#onDateSelectorChange: selectedDate=', this.selectedDate, ', typeof selectedDate=', typeof this.selectedDate)
      this.getCalendarEvents()
    }
  }

  private convertToPilotDate(date: Date): DayPilot.Date {
    // logger.debug('admin-calendar#convertToPilotDate: normalizedDate=', new DayPilot.Date(date, true))
    return new DayPilot.Date(date, this.localizationConfig.enable)
  }

  protected readonly Date = Date
  protected readonly AddressType = AddressType
  protected readonly WEEKDAYS = WEEKDAYS
}
