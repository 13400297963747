import { Component, OnInit, ViewChild } from '@angular/core'
import { DriverService } from '@service/driver'
import { DriverModel } from '@domain/driver'
import { logger } from 'nx/src/utils/logger'
import { ToastModule } from 'primeng/toast'
import { Table, TableModule } from 'primeng/table'
import { CardModule } from 'primeng/card'
import { Button } from 'primeng/button'
import { CheckboxModule } from 'primeng/checkbox'
import { InputTextModule } from 'primeng/inputtext'
import { ToolbarModule } from 'primeng/toolbar'
import { ReportService } from '../../../../services/src/lib/report.service'

@Component({
  selector: 'ui-drivers',
  standalone: true,
  imports: [
    ToastModule,
    TableModule,
    CardModule,
    Button,
    CheckboxModule,
    InputTextModule,
    ToolbarModule
  ],
  templateUrl: './drivers.component.html',
  styleUrl: './drivers.component.sass'
})
export class DriversComponent implements OnInit {
  @ViewChild('driverTable') table!: Table

  maxRows = 8
  first = 0
  isLoading = false
  drivers: DriverModel[] = []
  driversPdf: any

  constructor(
    private readonly driverSvc: DriverService,
    private readonly reportSvc: ReportService
  ) {}

  ngOnInit() {
    this.fetchDrivers()
  }


  applyGlobalFilter(event: Event) {
    const value = (event.target as HTMLInputElement).value
    this.table.filterGlobal(value, 'contains')
  }

  downloadDriversPDF() {
    this.reportSvc.fetchDriverPdf().subscribe((pdfBlob) => {
      // Create a URL that references the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create an anchor element and programmatically click it to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'drivers.pdf'; // Desired file name
      a.click();

      // Clean up the URL after the download
      window.URL.revokeObjectURL(url);
    });
  }

  fetchDriversPdf() {
    // this.isLoading = true
    this.reportSvc.fetchDriverPdf().subscribe({
      next: (value) => {
        this.driversPdf = value
      },
      complete: () => {
        this.isLoading = false
        logger.debug('drivers-component#fetchDriversPdf: driversPdf.size=', this.driversPdf?.size)
        const url = URL.createObjectURL(<Blob>this.driversPdf)
        window.open(url)
        // window.open(url, '_blank')
        // Clean up the URL after the download
        // window.URL.revokeObjectURL(url)
      }
    })
  }

  private fetchDrivers() {
    this.isLoading = true
    this.driverSvc.getActiveDrivers().subscribe({
      next: (value) => {
        this.drivers = value
      },
      complete: () => {
        this.isLoading = false
        logger.debug('drivers-component#fetchDrivers: drivers=', this.drivers)
      }
    })
  }
}
