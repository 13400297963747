import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DynamicDialogRef} from "primeng/dynamicdialog"
import {ButtonModule} from "primeng/button"
import {DialogModule} from "primeng/dialog"

@Component({
  selector: 'lib-common',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
  ],
  templateUrl: './dialog-footer.component.html',
  styleUrl: './dialog-footer.component.sass',
})
export class DialogFooterComponent {
  constructor(public ref: DynamicDialogRef) {
  }

  closeDialog() {
    this.ref.close()
  }
}
