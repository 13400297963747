import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'auth-register',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.sass'
})
export class RegisterComponent {}
