import {Component} from '@angular/core'
import {environment} from "../../../../../apps/frontend/src/environments/environment"
import {PanelModule} from 'primeng/panel'
import {CardModule} from 'primeng/card'
import {SplitterModule} from 'primeng/splitter'

@Component({
  selector: 'nav-bottom',
  standalone: true,
  imports: [
    PanelModule,
    CardModule,
    SplitterModule,
  ],
  templateUrl: './nav-bottom.component.html',
  styleUrl: './nav-bottom.component.sass',
})
export class NavBottomComponent {
  appName = environment.appName
  appVersion = environment.appVersion
  appDate = environment.appVersionDate
  appStage = environment.appStage
}
