<p-toast></p-toast>
<p-card header="Planungskalender" class="hcenter">

  <p-toolbar>
    <ng-template pTemplate="start">
      <p-floatLabel>
        <p-calendar id="date-selector" inputId="date-selector"
                    [(ngModel)]="selectedDate"
                    (ngModelChange)="onDateSelectorChange()"
                    [showIcon]="true"
                    [showClear]="true"
                    [numberOfMonths]="3"
                    class="date-selector"
                    showOtherMonths="true"
                    dateFormat="dd.mm.yy">
        </p-calendar>
        <!--[minDate]="startDate"-->
        <label for="date-selector">Datum eingeben</label>
      </p-floatLabel>
    </ng-template>

    <ng-template pTemplate="center">
      <p-buttonGroup>
        <p-button icon="pi pi-fast-backward"
                  (onClick)="prevMonth()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-backward"
                  (onClick)="prevWeek()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-caret-left"
                  (onClick)="prevDay()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-pause"
                  (onClick)="toDay()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-caret-right"
                  (onClick)="nextDay()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-forward"
                  (onClick)="nextWeek()"
                  severity="primary">
        </p-button>
        <p-button icon="pi pi-fast-forward"
                  (onClick)="nextMonth()"
                  severity="primary">
        </p-button>
      </p-buttonGroup>

      <div class="col-spacer"></div>
      <p-radioButton label="Tag" inputId="calendarView1"
                     [(ngModel)]="calendarView"
                     (onClick)="setViewMode()"
                     name="calendarView"
                     value="day">
      </p-radioButton>

      <div class="col-spacer"></div>
      <p-radioButton label="Woche" inputId="calendarView2"
                     [(ngModel)]="calendarView"
                     (onClick)="setViewMode()"
                     name="calendarView"
                     value="week">
      </p-radioButton>
    </ng-template>
    <!--
    <div class="col-spacer"></div>
    <p-radioButton name="calendarView"
                   label="Fahrzeug"
                   [(ngModel)]="calendarView"
                   (onClick)="setViewMode()"
                   inputId="calendarView3"
                   value="resource">
    </p-radioButton>
    -->

    <ng-template pTemplate="end">
      <span style="width: 8rem">{{ calendarEvents.length }} Fahrten</span>
    </ng-template>
  </p-toolbar>

  <daypilot-calendar
    [config]="pilotConfig"
    [events]="calendarEvents"
    #pilot_calendar>
  </daypilot-calendar>
</p-card>

<!-- Dialogs -->
<p-dialog header="Details Fahrt"
          [(visible)]="isRideDialogVisible"
          [modal]="true"
          [position]="'center'"
          class="ride-dialog"
          styleClass="p-fluid">

  <ng-template pTemplate="content">
    <div class="row" style="width: 100%">
      <div class="col">
        <label for="contact">Kunde</label>
        <p-dropdown id="contact" placeholder="Kunde wählen"
                    (onChange)="getAddresses()"
                    [(ngModel)]="currentRide.customerId"
                    [options]="contacts"
                    optionLabel="fullName"
                    optionValue="id"
                    emptyMessage="Nichts gefunden"
                    filter="true"
                    filterPlaceholder="Suchbegriff"
                    emptyFilterMessage="Nichts gefunden"
                    [showClear]="true">
        </p-dropdown>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="col80">
        <label for="from-address">Abholadresse</label>
        <p-dropdown id="from-address" placeholder="Abholadresse wählen"
                    (onChange)="checkDisabledAddressActions(AddressType.DEPARTURE)"
                    [disabled]="isAddrDepartureDisabled"
                    [(ngModel)]="currentRide.departureAddressId"
                    [options]="orderedAddresses"
                    optionLabel="fullAddress"
                    optionValue="addressId"
                    emptyMessage="Nichts gefunden"
                    filter="true"
                    filterPlaceholder="Suchbegriff"
                    emptyFilterMessage="Nichts gefunden"
                    [showClear]="true">
        </p-dropdown>
      </div>

      <div class="col-spacer"></div>
      <div class="col">
        <label for="from-address-actions">Aktionen</label>
        <div class="row">
          <p-buttonGroup id="from-address-actions">
            <p-button icon="pi pi-plus"
                      (onClick)="newAddress()"
                      [disabled]="isAddAddrDepartureDisabled"
                      severity="primary">
            </p-button>
            <p-button icon="pi pi-user"
                      (onClick)="addAddressToContact(AddressType.DEPARTURE)"
                      [disabled]="isAddAddrDepartureDisabled"
                      severity="primary">
            </p-button>
          </p-buttonGroup>
        </div>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="col80">
        <label for="to-address">Zieladresse</label>
        <p-dropdown id="to-address" placeholder="Zieladresse wählen"
                    (onChange)="checkDisabledAddressActions(AddressType.ARRIVAL)"
                    [disabled]="isAddrArrivalDisabled"
                    [(ngModel)]="currentRide.arrivalAddressId"
                    [options]="orderedAddresses"
                    optionLabel="fullAddress"
                    optionValue="addressId"
                    emptyMessage="Nichts gefunden"
                    filter="true"
                    filterPlaceholder="Suchbegriff"
                    emptyFilterMessage="Nichts gefunden"
                    [showClear]="true">
        </p-dropdown>
      </div>

      <div class="col-spacer"></div>
      <div class="col">
        <label for="to-address-actions">Aktionen</label>
        <div class="row">
          <p-buttonGroup id="to-address-actions">
            <p-button icon="pi pi-plus"
                      (onClick)="newAddress()"
                      [disabled]="isAddrArrivalDisabled"
                      severity="primary">
            </p-button>
            <p-button icon="pi pi-user"
                      (onClick)="addAddressToContact(AddressType.DEPARTURE)"
                      [disabled]="isAddAddrArrivalDisabled"
                      severity="primary">
            </p-button>
          </p-buttonGroup>
        </div>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="date-time-calendar">
        <label for="date-from">Fahrzeit von</label>
        <!--[minDate]="startDate"-->
        <p-calendar id="date-from"
                    [(ngModel)]="departureAt"
                    (onClose)="adjustDates()"
                    [showTime]="true"
                    [showClear]="true"
                    [stepMinute]="10"
                    [showIcon]="true"
                    [showButtonBar]="false"
                    required
                    dateFormat="dd.mm.yy">
        </p-calendar>
      </div>

      <div class="col-spacer"></div>
      <div class="date-time-calendar">
        <label for="date-to">Fahrzeit bis</label>
        <!--[minDate]="departureAt"-->
        <p-calendar id="date-to"
                    [(ngModel)]="arrivalAt"
                    (onClose)="setAppointment()"
                    [showTime]="true"
                    [showClear]="true"
                    [stepMinute]="10"
                    [showIcon]="true"
                    [showButtonBar]="false"
                    dateFormat="dd.mm.yy">
        </p-calendar>
      </div>

      <div class="col-spacer"></div>
      <div class="date-time-calendar">
        <label for="appointment-at">Terminzeitpunkt</label>
        <p-calendar
          id="appointment-at"
          class="month-calendar"
          [(ngModel)]="appointmentAt"
          [minDate]="startDate"
          [showTime]="true"
          [stepMinute]="5"
          [showClear]="true"
          [showIcon]="true"
          [showButtonBar]="false"
          dateFormat="dd.mm.yy">
        </p-calendar>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="col">
        <label for="vehicle">Fahrzeug</label>
        <p-dropdown id="vehicle" placeholder="Fahrzeug wählen"
                    [(ngModel)]="selectedVehicle"
                    [options]="vehicles"
                    (onChange)="onChangeVehicle()"
                    optionLabel="name"
                    optionValue="id"
                    emptyMessage="Nichts gefunden"
                    filter="true"
                    filterPlaceholder="Suchbegriff"
                    emptyFilterMessage="Nichts gefunden"
                    [showClear]="true">
        </p-dropdown>
      </div>
      <div class="col-spacer"></div>
      <div class="col">
        <label for="paying-method">bevorzugtes Zahlungsmittel</label>
        <p-dropdown id="paying-method" placeholder="Zahlungsmittel wählen"
                    [(ngModel)]="selectedPayingMethod"
                    [options]="payingMethods"
                    optionLabel="de"
                    optionValue="id"
                    emptyMessage="Nichts gefunden"
                    [showClear]="true">
        </p-dropdown>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="flex align-items-center">
      @for (handicap of handicaps; track handicap) {
        <p-checkbox
          [(ngModel)]="selectedHandicaps"
          [label]="handicap"
          [value]="handicap"
          name="group"
          class="checkbox-margin">
        </p-checkbox>
      }
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <!--
      <p-checkbox id="flat-rate" label="Pauschale"
                  [(ngModel)]="currentRide.flatCost"
                  class="checkbox"
                  [binary]="true">
      </p-checkbox>
      <p-checkbox id="invoice" label="Rechnung"
                  [(ngModel)]="ride.wasInvoiced"
                  class="checkbox"
                  [binary]="true">
      </p-checkbox>
      -->
      <p-checkbox id="free" label="Gratisfahrt (nur Fahrerentschädigung)"
                  [(ngModel)]="currentRide.isFreeOfCharge"
                  class="checkbox"
                  [binary]="true">
      </p-checkbox>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="col">
        <label for="flat-cost">Pauschale</label>
        <p-inputNumber id="flat-cost"
                       [(ngModel)]="currentRide.flatCost"
                       [showButtons]="true"
                       [min]="0"
                       [max]="1000">
        </p-inputNumber>
      </div>

      <div class="col-spacer"></div>
      <div class="col">
        <label for="additional-passengers">Zusätzliche Passagiere</label>
        <p-inputNumber id="additional-passengers"
                       [(ngModel)]="currentRide.additionalPassengers"
                       [showButtons]="true"
                       [min]="0"
                       [max]="5">
        </p-inputNumber>
      </div>

      <div class="col-spacer"></div>
      <div class="col">
        <label for="escorts">Begleitpersonen</label>
        <p-inputNumber id="escorts"
                       [(ngModel)]="currentRide.escorts"
                       [showButtons]="true"
                       [min]="0"
                       [max]="0">
        </p-inputNumber>
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <p-fieldset legend="Serie"
                  [(collapsed)]="arePeriodsCollapsed"
                  [toggleable]="true">
        <div class="row">
          <div class="col">
            <label for="period">Frequenz</label>
            <p-dropdown id="period" placeholder="Frequenz wählen"
                        [(ngModel)]="currentRide.periodId"
                        [options]="periods"
                        optionLabel="title"
                        optionValue="id"
                        [showClear]="true">
            </p-dropdown>
          </div>

          <div class="col-spacer"></div>
          <div class="col">
            <label for="series-days-group">Wochentage</label>
            <div id="series-days-group">
              @for (weekday of WEEKDAYS; track weekday) {
                <p-checkbox
                  [(ngModel)]="currentRide.periodDays"
                  [label]="weekday"
                  [value]="weekday"
                  class="checkbox"
                  name="days">
                </p-checkbox>
              }
            </div>
          </div>

          <div class="row">
            <div class="col" style="width:100%">
              <label for="period-end">Serienende</label>
              <p-inputMask type="text" id="period-end"
                           [(ngModel)]="periodFinishOn"
                           [placeholder]="'tt.mm.jjjj'"
                           autoClear="true"
                           showClear="true"
                           mask="99.99.9999">
              </p-inputMask>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>

    <div class="row-spacer"></div>
    <div class="field">
      <label for="comments">Kommentare</label>
      <textarea pInputTextarea id="comments"
                [(ngModel)]="currentRide.rideComments"
                rows="3"
                cols="30">
      </textarea>
    </div>

    <div class="row-spacer"></div>
    <div class="field">
      <label for="internal-comments">interne Kommentare</label>
      <textarea pInputTextarea id="internal-comments"
                [(ngModel)]="currentRide.rideInternalComment"
                rows="2"
                cols="30">
      </textarea>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button label="Abbrechen" icon="pi pi-times"
              (onClick)="hideRideDialog()"
              severity="secondary">
    </p-button>
    <p-button label="Folgefahrt (ab 3 Fahrten)" icon="pi pi-check"
              (onClick)="createFollowUpRide()"
              [disabled]="!isFollowUpRideButtonActive"
              severity="info">
    </p-button>
    <p-button label="Retourfahrt" icon="pi pi-check"
              (onClick)="createReturnRide()"
              [disabled]="!isRideValid()"
              severity="info">
    </p-button>
    <p-button label="Speichern" icon="pi pi-check"
              (onClick)="saveCalEvents()"
              [disabled]="!currentRide.departureAddressId || !currentRide.arrivalAddressId"
              severity="success">
    </p-button>
  </ng-template>
</p-dialog>

<!-- Address details -->
<p-dialog header="Details Adresse"
          [(visible)]="isAddressDialogVisible"
          [modal]="true"
          [position]="'center'"
          styleClass="p-fluid">

  <ng-template pTemplate="content">
    <div class="field col">
      <label for="description">Beschreibung</label>
      <input type="text" pInputText id="description"
             [(ngModel)]="address.description"
             autofocus />
    </div>

    <div class="row-spacer"></div>
    <div class="field col">
      <label for="address">Adresse</label>
      <input type="text" pInputText id="address"
             [(ngModel)]="address.street"
             required />
      @if (!address.street) {
        <small class="p-error">Adresse ist erforderlich.</small>
      }
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="field col">
        <label for="zip">PLZ</label>
        <p-inputNumber id="zip"
                       [(ngModel)]="address.zip"
                       [min]="1000"
                       [max]="9999"
                       [useGrouping]="false"
                       format="false"
                       required>
        </p-inputNumber>
        @if (!address.zip) {
          <small class="p-error">PLZ ist erforderlich.</small>
        }
      </div>

      <div class="col-spacer"></div>
      <div class="field col">
        <label for="location">Ort</label>
        <input pInputText id="location"
               [(ngModel)]="address.location"
               required />
        @if (!address.location) {
          <small class="p-error">Ort ist erforderlich.</small>
        }
      </div>
    </div>

    <div class="row-spacer"></div>
    <div class="row">
      <div class="field col">
        <label for="distance">Distanz zur Zentrale in Kilometer</label>
        <p-inputNumber id="distance"
                       [(ngModel)]="address.journeyDistance"
                       suffix=" km">
        </p-inputNumber>
      </div>

      <div class="col-spacer"></div>
      <div class="field col">
        <label for="duration">Fahrzeit zur Zentrale in Minuten</label>
        <p-inputNumber id="duration"
                       [(ngModel)]="address.journeyDuration"
                       suffix=" min.">
        </p-inputNumber>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button label="Abbrechen" icon="pi pi-times"
              (onClick)="hideAddressDialog()"
              severity="secondary">
    </p-button>
    <p-button label='Karte anzeigen' icon='pi pi-map'
              (onClick)="showRoute()"
              [disabled]="!isMapButtonActive"
              severity="info">
    </p-button>
    <p-button label="Speichern" icon="pi pi-check"
              (onClick)="saveAddress()"
              [disabled]="!address.street || !address.zip || !address.location"
              severity="success">
    </p-button>
  </ng-template>
</p-dialog>

<!-- Confirm delete -->
<p-dialog header="Löschen"
          [(visible)]="isDeleteDialogVisible"
          [modal]="true"
          [position]="'center'"
          styleClass="p-fluid">

  <ng-template pTemplate="content">
    <p>
      Warnung: Löschen von Fahrt <strong>{{ rideId }}</strong> kann nicht rückgängig gemacht werden!
    </p>
    @if (isSerialRide) {
      <p>Dies ist eine Serienfahrt</p>
    }
    <div class="row-spacer"></div>
    <div class="row">
      <p-checkbox id="return-ride" label="Hin- und Rückfahrt löschen"
                  [(ngModel)]="doDeleteEventRides"
                  class="checkbox"
                  [binary]="true">
      </p-checkbox>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button label="Abbrechen" icon="pi pi-times"
              (onClick)="hideDeleteDialog()"
              severity="secondary"
              autofocus>
    </p-button>
    <p-button label='Fahrt löschen' icon='pi pi-trash'
              (onClick)="onDeleteSingleRide()"
              severity="danger">
    </p-button>
    @if (isSerialRide) {
      <p-button label='Serie löschen' icon='pi pi-trash'
                (onClick)="onDeleteSerialRide()"
                severity="danger">
      </p-button>
    }
  </ng-template>
</p-dialog>


<!-- Confirm modify single or serial -->
<p-dialog header="Bearbeiten Serienfahrt {{rideId}}"
          [(visible)]="isEditSerialDialogVisible"
          [modal]="true"
          [position]="'center'"
          styleClass="p-fluid">

  <ng-template pTemplate="content">
    <i class="pi pi-exclamation-triangle p-mr-2" style="font-size: 2rem; color: orange;"></i>
    <span> Warnung: Das Bearbeiten eines einzelnen Einsatzes entfernt diesen aus der Serie!</span>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-button label="Abbrechen" icon="pi pi-times"
              (onClick)="hideEditSerialDialog()"
              severity="secondary"
              autofocus>
    </p-button>
    <p-button label='Nur diese Fahrt ändern' icon='pi pi-pencil'
              (onClick)="onEditSingleRide()"
              severity="primary">
    </p-button>
    <p-button label='Serie ändern' icon='pi pi-pencil'
              (onClick)="onEditSerialRide()"
              severity="primary">
    </p-button>
  </ng-template>
</p-dialog>

<!-- Confirm -->
<p-confirmDialog
  [style]="{ width: '450px' }">
</p-confirmDialog>
