<table>
  <tr>
    <td>Version:</td><td>{{ appVersion }}</td>
  </tr>
  <tr>
    <td>Datum:</td><td>{{ appVersionDate }}</td>
  </tr>
  <tr>
    <td>Stage:</td><td>{{ appStage }}</td>
  </tr>
</table>
