import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CheckboxModule } from 'primeng/checkbox'
import { CardModule } from 'primeng/card'
import { PasswordModule } from 'primeng/password'
import { FormsModule } from '@angular/forms'
import { DividerModule } from 'primeng/divider'
import { InputTextModule } from 'primeng/inputtext'
import { ButtonModule } from 'primeng/button'
import { logger } from 'nx/src/utils/logger'
import { AuthenticationService } from '../../../../services/src/lib/authentication.service'
import { Message, MessageService } from 'primeng/api'
import { MessagesModule } from 'primeng/messages'
import { DialogModule } from 'primeng/dialog'
import { ToastModule } from 'primeng/toast'
import { Router } from '@angular/router'
import { FloatLabelModule } from 'primeng/floatlabel'
import { LoginRequestModel, UserModel } from '../../../../domain/src/lib/user.model'

@Component({
  selector: 'auth-login',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxModule,
    CardModule,
    PasswordModule,
    DividerModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    MessagesModule,
    DialogModule,
    ToastModule,
    FloatLabelModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.sass'
})
export class LoginComponent implements OnInit {
  @ViewChild('loginInput', { static: false }) loginInput!: ElementRef;

  title = 'TixiPlan'
  user!: UserModel
  loginRequest: LoginRequestModel = { login: '', password: '' }
  message: string = ''
  messages: Message[]=[]
  isDialogVisible: boolean = true
  userId = 0
  isLoggedIn = false
  isLoginFailed = false
  roles: string[] = []

  constructor(
    private authSVC: AuthenticationService,
    private messageSVC: MessageService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    /*
     if (this.authSVC.isLoggedIn()) {
     this.isLoggedIn = true
     // this.roles = this.authSVC.getUserRoles()
     this.roles = this.authSVC.getRoles()
     }
     */
  }

  /*
   getMessage() {
   return 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out')
   }
   */

  clear() {
    this.loginRequest = { login: '', password: '' }
  }

  login() {
    this.showDialog()
    this.message = 'Trying to log in ...'
    logger.debug('login-component#login: login=', this.loginRequest.login) //=${this.loginRequest.password}`)
    this.authSVC.login(this.loginRequest).subscribe({
      next: (value) => {
        this.user = value
      },
      complete: () => {
        logger.debug('login-component#login: completed, user=', this.user)
        this.authSVC.saveLoginUser(this.user)
        this.cdr.detectChanges()
        this.authSVC.redirectUser()
      }
    })
    if (!this.user) {

      this.messageSVC.add({
        severity: 'error',
        summary: 'Loginfehler',
        detail: 'Benutzername und/oder Passwort falsch',
        life: 5000
      })
      this.focusOnLoginInput()

      /*
      this.messages = [
        {
          severity: 'error',
          summary: 'Loginfehler',
          detail: 'Benutzername und/oder Passwort falsch',
          life: 5000
        }
      ]
      */
    } else {
      // this.messages=[]
      this.hideDialog()
    }
    this.authSVC.redirectUser()
  }

  private showDialog(): void {
    this.isDialogVisible = true
  }

  private hideDialog(): void {
    this.isDialogVisible = false
  }

  // Method to programmatically focus the login input
  private focusOnLoginInput() {
    this.loginInput.nativeElement.focus();
  }

}
