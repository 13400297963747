export enum AddressType {
  DEPARTURE = 1,
  ARRIVAL = 2,
}

export interface AddressModel {
  id: number
  addressTypeId: number
  description: string
  street: string
  zip: string
  location: string
  isActivated: boolean
  isJourneyValid: boolean
  journeyDistance: number
  journeyDuration: number
  latitude: number
  longitude: number
  placeId: string
  fullAddress: string
}

export interface AddressDetailModel {
  id: number
  name: string
  de: string
}

/*
export interface AddressContactModel {
  id: number
  addressId: number
  contactId: number
  contactDetailId: number
  isHome: boolean
}

export interface ContactAddressModel {
  contactId: number
  addressId: number
  isCustomerAddress: boolean
  isContactActivated: boolean
  isAddressActivated: boolean
  fullAddress: string
  description?: string
  ordering?: number
}
 */

export interface AddressRouteModel {
  id: number
  address1Id: number
  address2Id: number
  ordering: number
}
