import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router'
import {inject} from "@angular/core"
import {AuthenticationService} from "./authentication.service"

export const authGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
  const authSVC = inject(AuthenticationService)
  const router = inject(Router)
  var isLoggedIn=false

  console.debug('authGuard@canActivate called')

  authSVC.isLoggedIn.subscribe(value => {
    isLoggedIn=value
  })

  if (!isLoggedIn) {
    console.debug('authGuard: LoggedIn=false, redirect to /login')
    router.navigate(['/login'])
    return false
  }
  console.debug('authGuard: LoggedIn=true')

  console.debug('authGuard: hasOneOfRoles roles=',route.data['roles'])
  if (route.data['roles'] && !authSVC.hasOneOfRoles(route.data['roles'])) {
    console.debug('authGuard: has no permission!, redirect to /')
    router.navigate(['/'])
    return false
  }

  console.debug('authGuard: isLoggedIn=true')
  // console.debug('authGuard: roles=', this.roles)

  return true
}
