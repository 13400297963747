import { Component, OnInit } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { Button } from 'primeng/button'
import { MenuModule } from 'primeng/menu'
import { MenubarModule } from 'primeng/menubar'
import { MenuItem, PrimeIcons, PrimeTemplate } from 'primeng/api'
import { Router, RouterLink } from '@angular/router'
import { TooltipModule } from 'primeng/tooltip'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { AuthenticationService } from '../../../../services/src/lib/authentication.service'
import { UserModel } from '../../../../domain/src/lib/user.model'
import { DialogFooterComponent } from '../../../common/src'
import { AboutComponent } from '../../../about/src'
import { HelpComponent } from '../../../help/src'
import { SupportComponent } from '../../../support/src'
import { AllRoleTypes, GroupRoleModel, RoleType } from '../../../../domain/src/lib/authorization.model'
import { UserService } from '../../../../services/src/lib/user.service'
import { UserProfileComponent } from '../../../user/src'

// import { AuthenticationService } from '@service/authentication'

@Component({
  selector: 'nav-top',
  standalone: true,
  imports: [
    Button,
    MenubarModule,
    PrimeTemplate,
    RouterLink,
    TooltipModule,
    MenuModule
  ],
  templateUrl: './nav-top.component.html',
  styleUrl: './nav-top.component.sass'
})
export class NavTopComponent implements OnInit {
  // Keyboard control
  /*
   @HostListener('window:keydown.control.k', ['$event'])
   handleKeyAdminCustomers(event: KeyboardEvent) {
   this.router.navigate(['/admin-customers'])
   }

   @HostListener('window:keydown.control.t', ['$event'])
   handleKeyAdminCalendar(event: KeyboardEvent) {
   this.router.navigate(['/admin-calendar'])
   }
   */

  title = 'TixiPlan'
  labelSize = 'small'
  barMenuItems: MenuItem[] = []
  userMenuItems!: MenuItem[]
  reportMenuItems!: MenuItem[]
  extraMenuItems!: MenuItem[]
  userRoles: GroupRoleModel[] = []
  loginRoles: string[] = []
  user!: UserModel
  items: { label?: string; icon?: string; separator?: boolean }[] = []
  isLoggedIn = false
  areCommonVisible = false
  ref!: DynamicDialogRef
  fontSize: number = 14; // Default font size
  minFontSize: number = 10; // Minimum font size boundary
  maxFontSize: number = 30; // Maximum font size boundary


  constructor(
    private authSVC: AuthenticationService,
    private userSVC: UserService,
    private dialogSVC: DialogService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getPermissions()
    // logger.debug('user=',this.userData)
    // Bar Menu
    if (this.hasOneOfRoles([RoleType.ADMINISTRATOR])) {
      this.barMenuItems = [
        {
          label: 'Fahrersicht',
          icon: PrimeIcons.DIRECTIONS,
          routerLink: '/driver-rides-all'
        },
        {
          label: 'Tagesverantwortung',
          icon: PrimeIcons.THUMBS_UP,
          routerLink: '/admin-responsibilities'
        },
        {
          separator: true
        }
      ]
    }
    const commonItems = [
      {
        label: 'Über Tixiplan',
        icon: PrimeIcons.COMMENT,
        command: () => {
          this.showAbout()
        }
      },
      {
        label: 'Hilfe',
        icon: PrimeIcons.BOOK,
        command: () => {
          this.showHelp()
        }
      },
      {
        separator: true
      },
      {
        label: 'Support',
        icon: PrimeIcons.PHONE,
        command: () => {
          this.showSupport()
        }
      }
    ]
    this.barMenuItems.push(...commonItems)
    // User Menu
    this.userMenuItems = [
      {
        label: this.loginName(),
        icon: PrimeIcons.USER_EDIT,
        command: () => {
          this.showUserProfile()
        }
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        icon: PrimeIcons.SIGN_OUT,
        command: () => {
          this.logout()
        }
      }
    ]
    this.reportMenuItems = [
      {
        label: 'Fahrtenliste',
        icon: PrimeIcons.DIRECTIONS,
        routerLink: '/event-report'
      },
      {
        label: 'Journal',
        icon: PrimeIcons.CALCULATOR
      },
      {
        label: 'Fahrer Rapport',
        icon: PrimeIcons.PAPERCLIP
      },
      {
        label: 'Fahrer Entschädigungen',
        icon: PrimeIcons.BITCOIN
      }
    ]
  }

  loginName(): string {
    // logger.debug('nav-top#loginName: loginName=', this.user)
    this.user = this.authSVC.getLoginUser()
    return this.user?.fullUserName ? this.user.fullUserName : (this.user?.login ? this.user.login : '')
  }

  showAbout() {
    const config: DynamicDialogConfig = {
      header: 'TixiPlan Info',
      contentStyle: { overflow: 'auto' },
      modal: true,
      maximizable: false,
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      templates: {
        footer: DialogFooterComponent
      }
    }
    this.ref = this.dialogSVC.open(AboutComponent, config)
  }

  showHelp() {
    const config: DynamicDialogConfig = {
      header: 'TixiPlan Hilfe',
      contentStyle: { overflow: 'auto' },
      modal: true,
      maximizable: false,
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      templates: {
        footer: DialogFooterComponent
      }
    }
    this.ref = this.dialogSVC.open(HelpComponent, config)
  }

  showSupport() {
    const config: DynamicDialogConfig = {
      header: 'TixiPlan Support',
      contentStyle: { overflow: 'auto' },
      modal: true,
      maximizable: false,
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      templates: {
        footer: DialogFooterComponent
      }
    }
    this.ref = this.dialogSVC.open(SupportComponent, config)
  }

  showUserProfile() {
    const config: DynamicDialogConfig = {
      header: 'Benutzerprofil',
      contentStyle: { overflow: 'auto' },
      modal: true,
      maximizable: false,
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      templates: {
        footer: DialogFooterComponent
      }
    }
    this.ref = this.dialogSVC.open(UserProfileComponent, config)
  }

  toggleCommonVisible() {
    if (this.areCommonVisible) {
      return false
    }
    this.areCommonVisible = true
    return true
  }

  private logout() {
    this.authSVC.logout()
    this.authSVC.redirectUser()
    // this.router.navigate(['/login'])
  }

  private getPermissions() {
    logger.debug('nav-top#getPermissions')
    this.authSVC.isLoggedIn.subscribe({
      next: (value) => {
        this.isLoggedIn = value
      },
      complete: () => {
        logger.debug('nav-top#getPermissions÷ loggedIn=', this.isLoggedIn)
        if (this.isLoggedIn) {
          this.getUserRoles()
          this.user = this.authSVC.getLoginUser()
          logger.debug('nav-top#getPermissions: user=', this.user)
          this.authSVC.redirectUser()
        }
      }
    })
  }

  private getUserRoles() {
    this.userSVC.getRolesByUserId(this.user.id).subscribe({
      next: (value) => {
        this.userRoles = value
      },
      complete: () => {
        logger.debug('nav-top#getUserRoles: userRoles=', this.userRoles)
      }
    })
  }

  // Method to increase font size
  increaseFontSize(): void {
    if (this.fontSize < this.maxFontSize) {
      this.fontSize++;
    }
  }

  // Method to decrease font size
  decreaseFontSize(): void {
    if (this.fontSize > this.minFontSize) {
      this.fontSize--;
    }
  }

  hasOneOfRoles(roles: RoleType[]): boolean {
    // logger.debug('nav-top#hasOneOfRoles: userRoles=', this.userRoles)
    // logger.debug('nav-top#hasOneOfRoles: roles=', roles)
    if (!this.loginRoles || this.loginRoles.length < 1) {
      this.loginRoles = this.authSVC.getLoginRoles()
    }
    // const roles:RoleType[]=[RoleType.ADMINISTRATOR,RoleType.DRIVER]
    let result = false
    for (let role of roles) {
      result = this.loginRoles.includes(role.toString())
      if (result) {
        break
      }
    }
    // logger.debug('nav-top#hasOneOfRoles: result=', result)
    return result
  }

  AllRoleTypes = AllRoleTypes
  protected readonly RoleType = RoleType
}
