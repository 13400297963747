<p-card header="Willkommen im Planungstool" class="hcenter" [style]="{height:'100%'}">

  <p-splitter
    [panelSizes]="[40,60]">

    <!-- User greeting -->
    <ng-template pTemplate>
      <p-card header="Hallo {{fullUserName}}" class="hcenter">
        <p>Letztes Login am {{ lastLogIn |date:"dd.MM.yyyy HH:mm" }}</p>
        @if (rideCount == 1) {
          <p>Heute ist 1 Fahrt geplant</p>
        } @else {
          <p>Heute sind {{ rideCount }} Fahrten geplant</p>
        }
        <ng-template pTemplate="footer">
          <p-button label="Zum Kalender" icon="pi pi-calendar-plus"
                    routerLink="/admin-calendar">
          </p-button>
        </ng-template>
      </p-card>
    </ng-template>

    <!-- Responsibilities (today) -->
    <ng-template pTemplate>
      <p-card header="Fahrzeugverantwortung" class="hcenter">
        <ng-template pTemplate="header">
          <!--<img alt="Card" src="https://primefaces.org/cdn/primeng/images/usercard.png" />-->
        </ng-template>
        <p-table
          [value]="vResponsibilities"
          sortField="fullName"
          sortMode="single"
          rowGroupMode="subheader"
          groupRowsBy="fullName"
          class="p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Von</th>
              <th>Bis</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="groupheader" let-driver let-expanded="expanded">
            <tr pRowGroupHeader>
              <td>
                {{ driver.fullName }} (<b>{{ driver.comment }}</b>)
              </td>
              <td>
                {{ driver.startOn |date:"EEEE, dd.MM.yyyy" }}
              </td>
              <td>
                {{ driver.finishOn |date:"EEEE, dd.MM.yyyy" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <td></td>
              <td>
                {{ rowData.phone |phoneNumber }}
              </td>
              <td>
                {{ rowData.phoneTitle }}
              </td>
            </tr>
          </ng-template>
        </p-table>

        <ng-template pTemplate="footer">
          <p-button label="Verwalten" icon="pi pi-thumbs-up"
                    routerLink="/admin-responsibilities">
          </p-button>
        </ng-template>
      </p-card>
    </ng-template>

  </p-splitter>
</p-card>
