<p-toast></p-toast>
<!--<p-messages [(value)]="messages" [enableService]="true" [closable]="true"></p-messages>-->
<p-card header="Fahrerliste" class="hcenter">
  <p-toolbar styleClass="mb-3 gap-2">
    <ng-template pTemplate="left">
      <p-button label="PDF öffnen" icon="pi pi-file-pdf"
                (onClick)="downloadDriversPDF()"
                severity="success">
      </p-button>
    </ng-template>
    <ng-template pTemplate="right">
        <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text"
                     (input)="applyGlobalFilter($event)"
                     placeholder="Suchen..." />
          </span>
    </ng-template>
  </p-toolbar>

  <p-table
    #driverTable
    [value]="drivers"
    dataKey="userId"
    selectionMode="single"
    [globalFilterFields]="['firstName', 'lastName']"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [rows]="maxRows"
    [first]="first"
    [loading]="isLoading"
    [rowsPerPageOptions]="[5, 8, 10, 15, 20]"
    [breakpoint]="'960px'"
    [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="Fahrer {first} bis {last} von {totalRecords}"
    responsiveLayout="stack"
    styleClass="p-datatable-gridlines">

    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Nachname</th>
        <th>Vorname</th>
        <th pSortableColumn="groupTitle">
          Gruppe
          <p-sortIcon field="groupTitle"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-driver>
      <tr>
        <td class="tab-col-id">{{ driver.userId }}</td>
        <td>{{ driver.lastName }}</td>
        <td>{{ driver.firstName }}</td>
        <td>{{ driver.groupTitle }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
