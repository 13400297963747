import {ApplicationConfig, LOCALE_ID} from '@angular/core'
import {provideRouter} from '@angular/router'
import {appRoutes} from './app.routes'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideHttpClient} from "@angular/common/http"
import {MessageService} from "primeng/api"
import {authInterceptor} from "../../../../libs/services/src/lib/auth.interceptor"
import {httpInterceptorProviders} from "../../../../libs/http-interceptors/src/lib/http-request.interceptor"
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common"

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(),
    MessageService,
    httpInterceptorProviders,
  ],
}
