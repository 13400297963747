import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardModule } from 'primeng/card'
import { ButtonModule } from 'primeng/button'
import { StorageService } from '@service/storage'
import { DriverService } from '@service/driver'
import { ContactModel } from '@domain/contact'
import { UserModel } from '@domain/user'
import { ContactService } from '@service/contact'
import { PhoneNumberPipe } from '../../../../../common/pipes/src/lib/phone-number-pipe'
import { LocalDatePipe } from '../../../../../common/pipes/src/lib/local-date-pipe'
import { logger } from 'nx/src/utils/logger'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { RouterLink } from '@angular/router'
import { RideService } from '@service/ride'
import { TableModule } from 'primeng/table'
import { VResponsibilityModel } from '@domain/driver'
import { SplitterModule } from 'primeng/splitter'

@Component({
  selector: 'admin-home',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    PhoneNumberPipe,
    LocalDatePipe,
    RouterLink,
    TableModule,
    SplitterModule
  ],
  providers: [
    // {provide: DynamicDialogRef, useValue: DynamicDialogRef},
    DialogService,
    DynamicDialogConfig
  ],
  templateUrl: './admin-home.component.html',
  styleUrl: './admin-home.component.sass'
})
export class AdminHomeComponent implements OnInit {
  lastLogIn = Date.now()  // TODO: set real date
  fullUserName: string = ''
  rideCount: number = 0
  vResponsibilities!: VResponsibilityModel[]
  user!: UserModel
  contact!: ContactModel
  ref!: DynamicDialogRef
  isLoading = false
  wasReloaded: boolean = false

  // formattedDate = this.now..getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
  todayStr: string = new Date().toISOString().substring(0, 10)

  constructor(
    private readonly storageSVC: StorageService,
    private readonly responsibilitySVC: DriverService,
    private readonly contactSVC: ContactService,
    private readonly rideSVC: RideService,
    public dialogSVC: DialogService,
    // private ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {
  }

  ngOnInit() {
    this.user = this.storageSVC.getLoginUser()
    console.debug('user=', this.user)
    this.fetchContact(Number(this.user.contactId))
    this.fetchResponsibilities()
    this.fetchRideCount()
    /* TODO: performs a loop!
     this.reloadPage()
     */
  }

  reloadPage() {
    if (!this.wasReloaded) {
      this.wasReloaded = true
      window.location.reload()
    }
  }

  fetchResponsibilities() {
    this.isLoading = true
    this.responsibilitySVC.getVResponsibilitiesByDayWeek(this.todayStr).subscribe({
      next: (values) => {
        this.vResponsibilities = values
      },
      complete: () => {
        logger.debug('admin-home#fetchResponsibilities: responsibilities=', this.vResponsibilities)
        this.isLoading = false
      }
    })
  }

  fetchContact(id: number) {
    this.isLoading = true
    this.contactSVC.getContactById(id).subscribe({
      next: (values) => {
        this.contact = values[0]
      },
      complete: () => {
        this.fullUserName = `${this.contact.firstName} ${this.contact.lastName}`
        logger.debug('admin-home#fetchContact: contact=', this.contact, ', fullUserName=', this.fullUserName)
        this.isLoading = false
      }
    })
  }


  fetchRideCount() {
    this.isLoading = true
    this.rideSVC.getRideCountFromDay(this.todayStr, 1).subscribe({
      next: (value) => {
        this.rideCount = value
      },
      complete: () => {
        logger.debug('admin-home#fetchRideCount: rideCount=', this.rideCount)
        this.isLoading = false
      }
    })
  }

  /*
   fetchGroups() {
   this.userService.getGroups().subscribe(values => {
   this.allGroups = values
   })
   }

   getGroupByUserId(id: number): GroupRoleModel | undefined {
   if (!this.allGroups) {
   this.fetchGroups()
   }
   const groups = this.allGroups.filter((values) => values.userId === id)
   console.debug('getGroupByUserId:groups=', groups)
   return groups.length > 0 ? groups[0] : undefined
   }

   mapGroups() {
   this.responsibilities.forEach(value => {
   const contact = this.getContactsByContactId(value.contactId)
   this.contacts.set(it.contactId, contact)
   })
   }

   trackResps(index: number, resp: DriverResponsibilityModel) {
   // console.debug('trackResps:', index, resp.contactId)
   return resp.id
   }
   */

  /*
   modifyResponsibilities() {
   logger.debug('modify-responsibilities: =', '')
   const config: DynamicDialogConfig = {
   data: {},
   header: `Verantwortlichkeiten`,
   // width: '50vw',
   style: {'width': '100vw', 'height': '100vh', 'max-width': '100%', 'max-height': '100%'},
   modal: true,
   maximizable: true,
   closable: true,
   breakpoints: {'960px': '75vw', '640px': '90vw'},
   // templates: {
   //   footer: MapFooterComponent,
   // },
   }
   // this.ref = this.dialogService.open(ResponsibilitiesComponent, config)
   this.ref = this.dialogService.open(AdminResponsibilitiesComponent, config)
   // this.ref.onClose.subscribe((ride: RideModel) => {
   // if (ride) {
   // logger.debug('map-dialog closed:', ride)
   // }
   // })
   }

   close() {
   this.ref.close()
   }
   */

}
