import {Component} from '@angular/core'
import {CommonModule} from '@angular/common'
import {DialogModule} from "primeng/dialog"
import {environment} from "../../../../../apps/frontend/src/environments/environment"

@Component({
  selector: 'about',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
  ],
  templateUrl: './about.component.html',
  styleUrl: './about.component.sass',
})
export class AboutComponent {
  appVersion = environment.appVersion
  appVersionDate = environment.appVersionDate
  appStage = environment.appStage
}
